import { createAsyncThunk } from "@reduxjs/toolkit";
import Interceptor from "../API/Interceptor";
const LoginRequest = createAsyncThunk(
  "login",
  async ({ userData, navigate }, { rejectWithValue }) => {
    try {
      const request = await Interceptor.post(`/login`, userData);
      localStorage.setItem("Authorization", "Bearer " + request.data.token);
      navigate("/generate-resume", { replace: true });
    } catch (e) {
      return rejectWithValue("Can't Login !!!");
    }
  }
);

export default LoginRequest;

import Home from "./Screens/Home";
import SnackBar from "./Components/SnackBar/SnackBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GenerateResume from "./Screens/GenerateResume/GenerateResume";
import { Navigate } from "react-router-dom";
import Users from "./Screens/Users/Users";
import Login from "./Screens/Login/Login";
import Signup from "./Screens/Signup/Signup";
import Skills from "./Screens/Skills/Skills";
import ResumePreview from "./Screens/ResumePreview/ResumePreview";
import Layout from "./Components/Common/Layout";
import Responsibilities from "./Screens/Responsibilities/Responsibilities";
import History from "./Screens/History";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Layout />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/home" element={<Home />} />
            <Route path="/generate-resume" element={<GenerateResume />} />
            <Route path="/users" element={<Users />}></Route>
            <Route path="/skills" element={<Skills />}></Route>
            <Route
              path="/responsibilities"
              element={<Responsibilities />}
            ></Route>
            <Route path="/history" element={<History />} />
            <Route path="/resume-preview" element={<ResumePreview />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <SnackBar />
    </>
  );
}

export default App;

import plus from "../../assets/plus.svg";
import classes from "./AddResponsibilityBtn.module.css";
const AddResponsibilityBtn = (props) => {
  return (
    <button
      className={classes["btn-container"]}
      style={{ ...props.style }}
      onClick={props?.function}
    >
      <img src={plus} style={{ ...props.iconStyle }} />
      <p style={{ ...props.paragraphStyle }}>{props.btnText}</p>
    </button>
  );
};

export default AddResponsibilityBtn;

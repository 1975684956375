import React, { useEffect, useState } from "react";
import HistoryModal from "./HistoryModal";
import {
  dispatchFolderActionError,
  editFolderAction,
} from "../../Store/Actions/historyFoldersActions";
import { useDispatch, useSelector } from "react-redux";

function ModalEditFolder({ closeModal, isOpen }) {
  const { currentFolder } = useSelector((state) => state.historyFolders);
  const [modalValue, setModalValue] = useState(currentFolder.folderName);
  useEffect(() => {
    setModalValue(currentFolder.folderName);
  }, [currentFolder.folderName]);
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (!modalValue.trim()) {
      dispatchFolderActionError(dispatch, "please fill the required feild");
      return;
    }
    dispatch(
      editFolderAction(
        {
          folderName: modalValue,
          folderID: currentFolder.folderID,
        },
        closeModal
      )
    );
  };

  const onChangeHandler = (e) => {
    setModalValue(e.target.value);
  };

  return (
    <HistoryModal
      functions={{
        submitHandler,
        handleClose: closeModal,
        onChangeHandler,
      }}
      texts={{
        title: "Edit folder name",
        placeHolder: "folder name",
        buttonText: "Edit",
        value: modalValue,
      }}
      isOpen={isOpen}
    />
  );
}

export default ModalEditFolder;

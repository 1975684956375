import React, { useState } from "react";
import AdminWrapper from "../../Components/Common/AdminWrapper";
import Folders from "../../Components/History/Folders";
import ResumesInHistory from "../../Components/History/ResumesInHistory";

function History() {
  const [showResume, setShowResume] = useState(false);

  const onOpenshowResume = () => {
    setShowResume(true);
  };

  const onCloseshowResume = () => {
    setShowResume(false);
  };

  return (
    <AdminWrapper>
      {!showResume ? (
        <Folders onOpenshowResume={onOpenshowResume} />
      ) : (
        <ResumesInHistory onBack={onCloseshowResume} />
      )}
    </AdminWrapper>
  );
}

export default History;

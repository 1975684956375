// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Skill_skillInactive__GBR9L{\n  padding: 10px 12px;\n  height: 20px;\n  border: 1px solid #e8e8ee;\n  border-radius: 13px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #313131;\n  cursor: pointer;\n  background: #FFFFFF;\n\n}\n.Skill_skillActive__hTJgN{\n  padding: 10px 12px;\n  height: 20px;\n  border: 1px solid #e8e8ee;\n  border-radius: 13px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #FFFFFF;\n  background: #1D5FE0;\n  cursor: pointer;\n}\n.Skill_span__Yf8Lw {\n  font-family: \"Montserrat\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 20px;\n  \n}\n\n\n", "",{"version":3,"sources":["webpack://./src/Components/Skills/Skill.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,mBAAmB;;AAErB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;AAEnB","sourcesContent":[".skillInactive{\n  padding: 10px 12px;\n  height: 20px;\n  border: 1px solid #e8e8ee;\n  border-radius: 13px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #313131;\n  cursor: pointer;\n  background: #FFFFFF;\n\n}\n.skillActive{\n  padding: 10px 12px;\n  height: 20px;\n  border: 1px solid #e8e8ee;\n  border-radius: 13px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #FFFFFF;\n  background: #1D5FE0;\n  cursor: pointer;\n}\n.span {\n  font-family: \"Montserrat\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 20px;\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skillInactive": "Skill_skillInactive__GBR9L",
	"skillActive": "Skill_skillActive__hTJgN",
	"span": "Skill_span__Yf8Lw"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import ConfirmModal from "../Common/ConfirmModal";
import { deleteHistoryAction } from "../../Store/Actions/histories";

function ModalDeleteResume({ closeModal, isOpen, deletedID, confirmDelete }) {
  const callback = () => {
    confirmDelete(deletedID);
    closeModal();
  };
  const onDelete = () => {
    deleteHistoryAction(deletedID, callback);
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      closeModal={closeModal}
      headerText="Delete Resume"
      descriptionText="This action will delete the selected resume from history, are you sure?"
      onConfirm={onDelete}
    />
  );
}

export default ModalDeleteResume;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import classes from "./Style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getSkillsData } from "../../Store/Actions/SkillsAction";

export default function SelectLabels({ view }) {
  const dispatch = useDispatch();

  const { chosenRole, arrayOfTechnology, employeeRole, allRoles, tagsByRole,skills} =
    useSelector(({ skillsReducer }) => {
      return {
        skills: skillsReducer?.skills,
        chosenRole: skillsReducer?.chosenRole,
        arrayOfTechnology: skillsReducer?.resume?.work,
        employeeRole: skillsReducer?.employeeRole,
        allRoles: skillsReducer?.allRoles,
        tagsByRole: skillsReducer?.tagsByRole,
      };
    });
  React.useEffect(()=>{
    tagsByRole?.[employeeRole]&&dispatch(getSkillsData(employeeRole, tagsByRole,arrayOfTechnology))

  },[tagsByRole])
  const handleChange = (event) => {
    dispatch(getSkillsData(event.target.value, tagsByRole,arrayOfTechnology));
  };

  return (
    <div className={classes.select}>
      <span>Please select a role</span>
      <FormControl style={{ width: "100%" }}>
        <Select
          disabled={view}
          value={chosenRole }
          onChange={handleChange}
          displayEmpty
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontSize: 16,
            color: "#000000",
            fontWeight: "500",
            borderRadius: "8px",
            "& .MuiSvgIcon-root": {
              display: view ? "none" : "",
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: 16,
              color: "#000000",
              fontWeight: "500",
            }}
            value={""}
          >
            {"Select Role"}
          </MenuItem>
          {allRoles?.map((element) => {
            return (
              <MenuItem
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontSize: 16,
                  color: "#000000",
                  fontWeight: "500",
                }}
                name={`${element}`}
                value={`${element}`}
                key={`${element}`}
              >
                {element}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

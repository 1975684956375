import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  users: {
    count: 0,
    result: [
      [
        { lable: "name", width: "2" },
        { lable: "role", width: "2" },
        { lable: "Actions", width: ".5" },
      ],
    ],
  },
  errorMessage: "",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    save: (state, { payload }) => {
      state.users = {
        ...state.users,
        count: payload.count,
        result: [state.users.result[0], ...payload?.result],
      };
    },
    delete: (state, { payload }) => {
      state.users.result = state.users.result.filter(
        (user) => user?.id !== payload
      );
    },
    add: (state, { payload }) => {
      state.users.result = [...state.users.result, payload];
    },
    edit: (state, { payload }) => {
      state.users.result = state.users.result.map((user) => {
        if (user.id === payload.id) {
          return payload;
        }
        return user;
      });
    },
    setError: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
});
export const usersActions = usersSlice.actions;

export default usersSlice.reducer;

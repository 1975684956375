import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const Layout = () => {
  const token = localStorage.getItem("Authorization");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
    if (!location.pathname.includes("signup") && !token) {
      return navigate("/");
    }
  }, []);
  return <Outlet />;
};

export default Layout;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  role: [],
  allRoles: [],
  skills: [],
  chosenRole: "",
  employeeRole: "",
  employeeId: "",
  employeeSkills: [],
  selectedResponsibilities: [],
  isGenerated: false,
  arrayOfResponsibilities: {},
  experienceId: "",
  resume: {
    general: {
      NAME: [],
      TITLE: [],
      "Total Experience": "",
      SUMMARY: [],
      "EDUCATION & CERTIFICATE": [],
      LANGUAGES: [],
      "TECHNOLOGIES & SKILLS": [],
    },
    work: [],
    project: [],
  },
  chosenSkills: [],
  uploadFileButton: false,
  viewAddResponsibilitiesModal: false,
  allResponsibilities: {},
  work: {
    id: "",
    title: "",
    company: "",
    startDate: "",
    endDate: "",
    responsibilties: [],
    TECHNOLOGIES: [],
    tagsByRole: [],
  },
};
const skillsSlice = createSlice({
  name: "skillsSlice",
  initialState,
  reducers: {
    getRole(state, action) {
      state.role = action?.payload?.data;
      state.allRoles = action?.payload?.allRoles;
      state.tagsByRole = action?.payload?.tagsByRole;
    },
    getSkills(state, action) {
      state.skills = action?.payload?.skills;
      state.chosenRole = action?.payload?.chosenRole;
      state.employeeSkills = action?.payload?.selectedSkills;
    },
    addSkillsToEmployee(state, action) {
      state.skills = action?.payload?.skills;
      state.employeeSkills = action?.payload?.selectedSkills;
    },
    addResponsibilities(state, action) {
      state.selectedResponsibilities = action?.payload;
    },
    updateResponsibilities(state, action) {
      state.employeeSkills = action?.payload;
    },
    setDefualtSkills(state, action) {
      state.employeeSkills = action?.payload;
    },
    updateArrayOfResponsibilities(state, action) {
      state.resume.work= state.resume.work.reduce((pre, curr) => {
        if (curr?.id === action?.payload?.employeeId) {
          return [
            ...pre,
            { ...curr, responsibility: [...curr.responsibility,...action?.payload?.responsibilities] },
          ];
        } else {
          return [...pre, curr];
        }
      }, []);
    },
    updateRole(state, action) {
      state.chosenRole = action?.payload;
    },
    saveResume(state, action) {
      state.resume = action?.payload;
    },
    updateButton(state) {
      state.uploadFileButton = !state.uploadFileButton;
    },
    resetresponsibilties(state, action) {
      state.employeeSkills = action?.payload?.data;

      state.employeeRole = action?.payload?.employeeRole;
      state.employeeId = action?.payload?.employeeId;
      state.selectedResponsibilities = [];
      state.skills = [];
      state.chosenRole = "";
    },
    setEmployeeId(state, action) {
      state.employeeId = action?.payload;
    },
    addAllResponsibilities(state, action) {
      state.arrayOfResponsibilities = action?.payload;
    },

    resetUploadedFile(state, action) {
      state.uploadFileButton = false;
    },
    createExperience(state) {
      state.work.id = state?.employeeId;
      const obj = {
        id: state?.employeeId,
        title: state?.work?.title,
        company: state?.work?.company,
        date: `${state?.work?.startDate} - ${state?.work?.endDate}`,
        TECHNOLOGIES: [],
        responsibility: [],
      };
      state.resume.work = [...state?.resume?.work, obj];
    },
    fillWorkExperienceData(state, action) {
      state.work[action?.payload?.key] = action?.payload?.value;
    },
    editExperience(state, action) {
      state.resume.work[action?.payload?.employeeId] = action?.payload?.data;
    },
    editResponsibilities(state, action) {
      state.resume.work[action?.payload?.employeeId].responsibility =
        action?.payload?.data;
    },
    editEmpoyeeName(state, action) {
      state.resume.general.NAME[0] = action?.payload;
    },
    editEmpoyeeSpeciality(state, action) {
      state.resume.general.TITLE[0] = action?.payload;
    },
    editTotalExperience(state, action) {
      state.resume.general["Total Experience"] = action?.payload;
    },
    editSummary(state, action) {
      state.resume.general.SUMMARY = action?.payload;
    },
    addEducation(state, action) {
      state.resume.general["EDUCATION & CERTIFICATE"] = [
        ...state?.resume?.general?.["EDUCATION & CERTIFICATE"],
        action?.payload,
      ];
    },
    editEducation(state, action) {
      state.resume.general["EDUCATION & CERTIFICATE"] = action?.payload;
    },
    addLanguage(state, action) {
      state.resume.general["LANGUAGES"] = [
        ...state?.resume?.general?.["LANGUAGES"],
        action?.payload,
      ];
    },
    editLanguage(state, action) {
      state.resume.general["LANGUAGES"] = action?.payload;
    },
    addExperienceId(state, action) {
      state.experienceId = action?.payload;
    },
    addExperienceTechnology(state, action) {
      state.resume.work[action?.payload?.experienceId].TECHNOLOGIES = [
        ...state?.resume?.work?.[action?.payload?.experienceId]?.TECHNOLOGIES,
        action?.payload?.technology,
      ];
    },
    AddProject(state, action) {
      state.resume.project = [...state?.resume?.project, action?.payload];
    },
    deleteExperience(state, action) {
      state.resume.work = state.resume.work.filter(
        (work) => work.id !== action.payload
      );
    },
    editTechnologiesSkills(state, action) {
      state.resume.general["TECHNOLOGIES & SKILLS"] = action?.payload;
    },
    editExperienceTechnologies(state, action) {
      state.resume.work[action?.payload?.id].TECHNOLOGIES =
        action?.payload?.technologies;
    },
    editProject(state, action) {
      state.resume.project[action?.payload?.id] = action?.payload?.data;
    },
    deleteProject(state, action) {
      state.resume.project = state?.resume?.project?.filter(
        (item) => item?.id !== action?.payload
      );
    },
    addProjectTechnology(state, action) {
      state.resume.project[action?.payload?.projectId].TECHNOLOGIES = [
        ...state?.resume?.project?.[action?.payload?.projectId]?.TECHNOLOGIES,
        action?.payload?.technology,
      ];
    },

    editProjectTechnologies(state, action) {
      state.resume.project[action?.payload?.id].TECHNOLOGIES =
        action?.payload?.technologies;
    },
  },
});
export const skillsAction = skillsSlice.actions;

export default skillsSlice.reducer;

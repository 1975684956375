import { responsibilitiesActions } from "../Slices/ResponsibilitiesSlice";
import {
  getAllResponsibilities,
  addNewResponsibilitiesService,
  updateResponsibilityService,
  deleteResponsibilityService,
} from "../Services";

export const getAllResponsibilitiesAction =
  (limit, offset, search) => async (dispatch) => {
    try {
      const data = await getAllResponsibilities(offset, limit, search);

      dispatch(responsibilitiesActions?.getResponsibilities(data));
      return;
    } catch (error) {
      console.log(error);
    }
  };

export const addNewResponsibilities =
  (data, search, callback) => async (dispatch) => {
    dispatch(responsibilitiesActions?.isLoading());

    try {
      await addNewResponsibilitiesService(data);
      if (data.responsibility.startsWith(search))
        dispatch(responsibilitiesActions?.addNewResponsibilities(data));
      callback();
    } catch (error) {
      dispatch(responsibilitiesActions?.failed());
    }
  };

export const deleteResponsibility = (id) => async (dispatch) => {
  dispatch(responsibilitiesActions?.isLoading());
  try {
    await deleteResponsibilityService(id);

    dispatch(responsibilitiesActions?.deleteResponsibility({ id }));
  } catch (error) {
    dispatch(responsibilitiesActions?.failed());
  }
};
export const updateResponsibility = (data, callback) => async (dispatch) => {
  dispatch(responsibilitiesActions?.isLoading());
  try {
    await updateResponsibilityService(data);
    dispatch(responsibilitiesActions?.updateResponsibility(data));
    callback();
  } catch (error) {
    return dispatch(responsibilitiesActions.failed());
  }
};

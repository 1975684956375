import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  technologies: {
    count: 0,
    result: [
      [
        { lable: "Skills", width: "2" },
        { lable: "Added", width: "2" },
        { lable: "Actions", width: ".5" },
      ],
    ],
  },
};

export const technologiesSlice = createSlice({
  name: "technologies",
  initialState,

  reducers: {
    save: (state, { payload }) => {
      state.technologies = {
        ...state.technologies,
        count: payload?.count,
        result: [state.technologies.result[0], ...payload?.result],
      };
    },
    delete: (state, { payload }) => {
      state.technologies.result = state.technologies.result.filter(
        (technology) => technology?.id !== payload
      );
    },
    add: (state, { payload }) => {
      state.technologies.result = [...state.technologies.result, payload];
    }
    ,
    edit: (state, { payload }) => {
      state.technologies.result = state.technologies.result.map(
        (technology) => {
          if (technology?.id === payload?.id) {
            return payload;
          }
          return technology;
        }
      );
    }
  },
});
export const technologiesActions = technologiesSlice.actions;

export default technologiesSlice.reducer;

import React from 'react';
import classes from "./row.module.css"
function multiRow(props) {
    return (
        <td className={classes.multiRow} style={{ flex: `${props.width}` }}>
            <p>{props.data}</p>
        </td>
    );
}

export default multiRow;
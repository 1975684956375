// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resizable_resize__UbCZh {\n  width: 100%;\n  overflow-x: hidden;\n  position: relative;\n  z-index: 999;\n  cursor: row-resize;\n  border-bottom: 1px solid rgb(204, 204, 204);\n  padding-bottom: 5px;\n}\n\n.resizable_resize__UbCZh:hover {\n  border-bottom: 2px solid rgb(204, 204, 204);\n}\n\n.resizable_resize__UbCZh > p {\n  flex-grow: 1;\n  transition: flex-wrap 0.3s;\n  z-index: 100;\n  padding: 0 10px;\n}\n\n@media print {\n  .resizable_resize__UbCZh {\n    border-bottom: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/resizable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,YAAY;EACZ,0BAA0B;EAC1B,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".resize {\n  width: 100%;\n  overflow-x: hidden;\n  position: relative;\n  z-index: 999;\n  cursor: row-resize;\n  border-bottom: 1px solid rgb(204, 204, 204);\n  padding-bottom: 5px;\n}\n\n.resize:hover {\n  border-bottom: 2px solid rgb(204, 204, 204);\n}\n\n.resize > p {\n  flex-grow: 1;\n  transition: flex-wrap 0.3s;\n  z-index: 100;\n  padding: 0 10px;\n}\n\n@media print {\n  .resize {\n    border-bottom: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resize": "resizable_resize__UbCZh"
};
export default ___CSS_LOADER_EXPORT___;

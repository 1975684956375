import React, { useEffect, useState } from "react";
import AdminWrapper from "../../Components/Common/AdminWrapper";
import Header from "../../Components/Common/Header";
import Pagination from "../../Components/Common/Pagination";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../Components/Users/Modal";
import Table from "./Table";
import {
  getAllResponsibilitiesAction,
  addNewResponsibilities,
  updateResponsibility,
  deleteResponsibility,
} from "../../Store/Actions/ResponsibilitiesAction";
import Input from "../../Components/Common/Input";
import ConfirmModal from "../../Components/Common/ConfirmModal";
const Responsibilities = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [timer, setTimer] = useState(null);
  const [generate, setGenerate] = useState({
    role: "",
    category: "",
    responsibility: "",
  });
  const { responsibilities } = useSelector(({ responsibilitiesReducer }) => {
    return {
      responsibilities: responsibilitiesReducer?.responsibilities,
    };
  });

  const handleCloseAdd = () => {
    setOpenAddModal(false);
    setGenerate({
      role: "",
      category: "",
      responsibility: "",
    });
  };
  const handelCloseUpdate = () => {
    setOpenUpdateModal(false);
    setGenerate({
      role: "",
      category: "",
      responsibility: "",
    });
  };

  const getAllResponsibilities = async (
    page = 1,
    limit = 5,
    searchString = search
  ) => {
    dispatch(getAllResponsibilitiesAction(page, limit, searchString));
  };
  useEffect(() => {
    getAllResponsibilities(1, 5);
  }, []);

  const submitHandlerEdit = async (e) => {
    e.preventDefault();

    dispatch(updateResponsibility(generate, handelCloseUpdate));
  };
  const handleOpenAdd = () => {
    setOpenAddModal(true);
  };

  const onChangeHandler = ({ target }) => {
    setGenerate((state) => {
      return { ...state, [target?.id]: target?.value };
    });
  };
  const openModalToUpdate = (id) => {
    const data = responsibilities?.result?.find((item) => item?.id === id);
    setGenerate((prev) => data);
    setOpenUpdateModal(true);
  };
  const submitHandlerAdd = async (event) => {
    event.preventDefault();
    try {
      dispatch(addNewResponsibilities(generate, search, handleCloseAdd));
    } catch (error) {
      console.log(error);
    }
  };
  const searchHandler = (e) => {
    const searchValue = e.target?.value;
    setSearch(searchValue);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      getAllResponsibilities(1, 5, searchValue);
    }, 1000);
    setTimer(newTimer);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedID, setDeletedID] = useState(null);
  const openModalDelete = (id) => {
    setDeleteModal(true);
    setDeletedID(id);
  };
  const closeModalDelete = () => {
    setDeleteModal(false);
  };
  const onConfirmDelete = async () => {
    dispatch(deleteResponsibility(deletedID));
    closeModalDelete();
  };
  return (
    <AdminWrapper>
      <Header
        text={{
          button: "Add new Responsibilities",
          heading: "Responsibilities",
        }}
        count={`${responsibilities?.count || 0} responsibilities`}
        handler={handleOpenAdd}
        placeholder="Search responsibility"
        onChangeHandler={searchHandler}
      />

      <Table
        to="/"
        data={responsibilities?.result || []}
        flex="1.5"
        editHandler={openModalToUpdate}
        deleteHandler={openModalDelete}
      />

      <Pagination
        count={responsibilities?.count ? responsibilities?.count : 0}
        current={
          responsibilities?.result?.length
            ? responsibilities?.result?.length
            : 0
        }
        PaginationHandler={getAllResponsibilities}
      />
      <Modal
        buttonText="Save"
        header="Add new Responsibilities"
        submitHandler={submitHandlerAdd}
        handleClose={handleCloseAdd}
        condition={
          generate?.role && generate.category && generate?.responsibility
        }
        open={openAddModal}
      >
        <Input
          text="Responsibility"
          id="responsibility"
          value={generate?.responsibility}
          onChangeHandler={onChangeHandler}
          type="text"
          placeholder="Responsibility"
        ></Input>
        <Input
          text="Role"
          onChangeHandler={onChangeHandler}
          id="role"
          value={generate?.role}
          type="text"
          placeholder="Role"
        ></Input>
        <Input
          text="Category"
          id="category"
          value={generate?.category}
          onChangeHandler={onChangeHandler}
          type="text"
          placeholder="Category"
        ></Input>
      </Modal>
      <Modal
        buttonText="Save"
        header="Update Responsibility"
        submitHandler={submitHandlerEdit}
        handleClose={handelCloseUpdate}
        condition={
          generate?.role && generate.category && generate?.responsibility
        }
        open={openUpdateModal}
      >
        <Input
          text="Responsibility"
          id="responsibility"
          value={generate?.responsibility}
          onChangeHandler={onChangeHandler}
          type="text"
          placeholder="Responsibility"
        ></Input>
        <Input
          text="Role"
          onChangeHandler={onChangeHandler}
          id="role"
          value={generate?.role}
          type="text"
          placeholder="Role"
        ></Input>
        <Input
          text="Category"
          id="category"
          value={generate?.category}
          onChangeHandler={onChangeHandler}
          type="text"
          placeholder="Category"
        ></Input>
      </Modal>

      <ConfirmModal
        isOpen={deleteModal}
        closeModal={closeModalDelete}
        headerText="Delete Skill"
        descriptionText="This action will delete the selected skill, are you sure?"
        onConfirm={onConfirmDelete}
      />
    </AdminWrapper>
  );
};

export default Responsibilities;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editProjectAction } from "../../Store/Actions";
import { v4 as uuidv4 } from "uuid";
import Input from "../Common/Input";
import Buttons from "./Buttons";

export default function EditProject({ setOpen = () => {} }) {
  const {project,employeeId} = useSelector(({skillsReducer}) => {return{project:skillsReducer?.resume?.project,
    employeeId:skillsReducer?.employeeId}});
    const dispatch = useDispatch();
    
    
    const [selectedProject, setProject] = useState({
      title:project?.[employeeId]?.NAME ,
      description: project?.[employeeId]?.DESCRIPTION?.join("\n\n")
    });
    console.log(selectedProject,employeeId);
  const onChangeHandlerForTitle = (e) => {
    setProject(prev=>{
      return {...prev,title:e.target?.value}
    });
  };
  const onChangeHandlerForDescription = (e) => {
    setProject(prev=>{
      return {...prev,description:e.target?.value}
    });
  };
  const saveHandler = (e) => {
    e.preventDefault();
  const newArr=selectedProject?.description?.split("\n").reduce((acc,item)=>{
    if(item !==''){
      return [...acc,item]
    }else{
      return [...acc]
    }
  },[])
  const newData = {
    id:uuidv4(),
    DESCRIPTION:newArr,
    NAME:[`${selectedProject.title}`]
  }
    dispatch(editProjectAction(newData,employeeId));
    setOpen(false);
  };
  
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  const textareaStyle = {
    width: "503px",
    height: "118px",
    padding: "19px 16px",
    boxSizing: "border-box",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    resize: "none",
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1D2C34",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <Input
        text=""
        value={selectedProject?.title}
        width="487px"
        placeholder="Title"
        type="text"
        onChangeHandler={onChangeHandlerForTitle}
        name="title"
      />
      <textarea
        placeholder="Description"
        value={selectedProject?.description}
        onChange={onChangeHandlerForDescription}
        style={{ ...textareaStyle }}
        name="description"
      />
      <Buttons
        saveHandler={() => {}}
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

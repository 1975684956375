import React from "react";
import { v4 as uuidv4 } from "uuid";
import MultiRow from "../../Components/Common/MultiRow";
import View from "../../Components/Common/View";
import upDown from "../../assets/upDown.svg";
import clasessTable from "../../Components/Common/table.module.css";
export default function Table({ data = [], editHandler, deleteHandler }) {
  const columns = [
    { lable: "Responsibility", code: "responsibility", width: "50%" },
    { lable: "Role", code: "role", width: "20%" },
    { lable: "Category", code: "category", width: "20%" },
    { lable: "Action", code: "buttons", width: "5%", textAlign: "center" },
  ];
  const renderTdValue = (key, data) => {
    return {
      role: <MultiRow data={data?.[key]} width="20%" key={uuidv4()} />,
      category: <MultiRow data={data?.[key]} width="20%" key={uuidv4()} />,
      responsibility: (
        <MultiRow data={data?.[key]} width="50%" key={uuidv4()} />
      ),
      buttons: (
        <View
          key={uuidv4()}
          data={data}
          clickHandler={{ editHandler, deleteHandler }}
          width="5%"
        />
      ),
    }[key];
  };
  return (
    <table>
      <thead>
        <tr>
          {columns?.map((title) => {
            return (
              <th key={uuidv4()} style={{ flex: title.width }}>
                {title.lable !== "" && (
                  <img className={clasessTable.upDown} src={upDown} alt="" />
                )}
                <p style={{ textAlign: title?.textAlign }}>{title.lable}</p>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <>
          {data?.map((item) => {
            return (
              <tr className="tr" key={uuidv4()}>
                {columns?.map((column) => {
                  return renderTdValue(column?.code, item);
                })}
              </tr>
            );
          })}
        </>
      </tbody>
    </table>
  );
}

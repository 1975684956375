import React from "react";
import classes from "./input.module.css";

function Input(props) {
  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>{props.text}</label>
      <input
        style={{ width: `${props.width}`, height: `${props?.height}` }}
        onChange={props?.onChangeHandler}
        id={props?.id}
        placeholder={props?.placeholder}
        className={classes.input}
        required={!props?.required}
        type={props?.type}
        value={props?.value}
        name={props?.name}
      />
    </div>
  );
}

export default Input;

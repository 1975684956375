import React from "react";
import clasessTable from "./table.module.css";
import ActionsButton from "./ActionsButton";

function View({ data, clickHandler, width }) {
  return (
    <td className={clasessTable.actionsContainer} style={{ flex: `${width}` }}>
      <div>
        <ActionsButton
          id={data?.id}
          clickHandler={clickHandler?.editHandler}
          style={{ color: "rgba(29, 95, 224, 1)" }}
          text="Edit"
        />
        <ActionsButton
          id={data?.id}
          clickHandler={clickHandler?.deleteHandler}
          style={{ color: "rgba(192, 34, 36, 1)" }}
          text="Delete"
        />
      </div>
    </td>
  );
}

export default View;

import React from "react";
import "../../app.css";
import Interceptor from "../../API/Interceptor";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { saveResume, buttonHandler } from "../../Store/Actions";
import { useNavigate } from "react-router-dom";
import AdminWrapper from "../../Components/Common/AdminWrapper";
import UploadSheet from "../../Components/GenerateResume/UploadFile";
function GenerateResume(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uploadFileButton } = useSelector(({ skillsReducer }) => {
    return {
      uploadFileButton: skillsReducer?.uploadFileButton,
    };
  });

  const submitHandler = async (e) => {
    const data = new FormData();
    data.append(`file`, e.target.files[0]);
    try {
      await Interceptor.post(`/generate-resume?name=first`, data).then(
        (res) => {
          let work = [];
          Object.values(res?.data?.work || {})?.map((item) => {
            let obj = {};
            obj.id = uuid();
            obj.date = `${item["DATE FROM"]?.[0]} - ${item["DATE TO"]?.[0]}`;
            obj.company = item["COMPANY"]?.[0];
            obj.responsibility = item["RESPONSIBILITIES"];
            obj.TECHNOLOGIES = item["TECHNOLOGIES"];
            obj.title = item["TITLE"]?.[0];
            work.push(obj);
          });

          const project = Object.values(res.data.project || {});
          dispatch(
            saveResume({
              work,
              project,
              general: res.data.general,
              // technologies: res.data.technologies,
            })
          );
        }
      );
      dispatch(buttonHandler());
      navigate("/resume-preview");
    } catch (error) {
      console.log(error);
    }
  };

  const onClickHandler = () => {
    dispatch(
      saveResume({
        general: {
          NAME: [],
          TITLE: [],
          "Total Experience": "",
          SUMMARY: [],
          "EDUCATION & CERTIFICATE": [],
          LANGUAGES: [],
        },
        work: [],
        project: [],
      })
    );
    navigate("/resume-preview");
  };
  return (
    <AdminWrapper>
      {!uploadFileButton && (
        <UploadSheet
          onClickHandler={onClickHandler}
          submitHandler={submitHandler}
        />
      )}
    </AdminWrapper>
  );
}

export default GenerateResume;

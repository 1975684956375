import { technologiesActions } from "../Slices/technologies";
import {
  getRequest,
  deleteRequest,
  postRequest,
  updateRequest,
} from "../Services";
export const getTechnologiesAction =
  (page, limit, search) => async (dispatch) => {
    try {
      const { data } = await getRequest(
        `get-technologies?limit=${limit}&offset=${
          (page - 1) * limit
        }&search=${search}`
      );
      let { result, count } = data;
      dispatch(technologiesActions.save({ result, count }));
    } catch (error) {
      console.log(error);
    }
  };
export const deleteTechnologyAction = (id) => async (dispatch) => {
  try {
    await deleteRequest(`delete-technology/${id}`);
    dispatch(technologiesActions.delete(id));
  } catch (error) {
    console.log(error);
  }
};
export const addTechnologyAction =
  (obj, search, callback) => async (dispatch) => {
    try {
      const { data } = await postRequest("add-technology", obj);
      if (obj.Skills.startsWith(search))
        dispatch(
          technologiesActions.add({
            id: data,
            Skills: obj.Skills,
            photo: obj.photo,
            Added: "2018",
          })
        );
      callback();
    } catch (error) {
      console.log(error);
    }
  };
export const editTechnologyAction = (obj, callback) => async (dispatch) => {
  try {
    const { data } = await updateRequest(`edit-technology`, obj);
    dispatch(technologiesActions.edit(obj));
    callback();
  } catch (error) {}
};
export const getAllTechnologiesAction = async () => {
  try {
    const { data } = await getRequest(`get-all-technologies`);
    return data;
  } catch (error) {}
};

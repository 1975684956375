// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionsButton_button__oqung{\nfont-size: 14px;\nfont-weight: 500;\nline-height: 17px;\nletter-spacing: 0em;\ntext-align: left;\nborder:none;\noutline:none;\ncursor:pointer;\nbackground-color: white;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Common/actionsButton.module.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;AAChB,WAAW;AACX,YAAY;AACZ,cAAc;AACd,uBAAuB;;AAEvB","sourcesContent":[".button{\nfont-size: 14px;\nfont-weight: 500;\nline-height: 17px;\nletter-spacing: 0em;\ntext-align: left;\nborder:none;\noutline:none;\ncursor:pointer;\nbackground-color: white;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "actionsButton_button__oqung"
};
export default ___CSS_LOADER_EXPORT___;

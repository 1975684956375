// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_submitBtn__kBqnG {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  /* width: 169px; */\n  height: 52px;\n  padding: 16px 32px;\n  border: 2px solid #1d5fe0;\n  border-radius: 8px;\n\n  color: rgba(29, 95, 224, 1);\n  background-color: white;\n\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: center;\n}\n.button_submitBtn__kBqnG img {\n  width: 14px;\n  height: 14px;\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/button.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;;EAElB,2BAA2B;EAC3B,uBAAuB;;EAEvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".submitBtn {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  /* width: 169px; */\n  height: 52px;\n  padding: 16px 32px;\n  border: 2px solid #1d5fe0;\n  border-radius: 8px;\n\n  color: rgba(29, 95, 224, 1);\n  background-color: white;\n\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: center;\n}\n.submitBtn img {\n  width: 14px;\n  height: 14px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitBtn": "button_submitBtn__kBqnG"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import classes from './Style.module.css'
export default function EditButton({editHandler,title="Edit",icon}) {
  return (
    <button className={classes.editButton} onClick={editHandler}>
      <img src={icon} alt={title} />
      <span>{title}</span>
    </button>

  )
}

import React,{useState} from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Body from "../Components/Body/Body";
import classes from "./Home.module.css";
import ChooseRequiredSkills from "../Components/Body/ChooseRequiredSkills";
import JobResponsibilities from "../Components/JobResponsibilities/JobResponsibilities";
import RequiredSkills from "../Components/Body/RequiredSkills";
export default function Home() {
  const [view , setView] = useState(false)
  const genrateHandler = () =>{
    setView(prev=>!prev)
  }
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.homeBody}>
        <Body genrateHandler={genrateHandler} Component={view?RequiredSkills:ChooseRequiredSkills} view={view} setView={setView}/>
        {view && <JobResponsibilities /> }
        
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import ConfirmModal from "../Common/ConfirmModal";
import { deleteFolderAction } from "../../Store/Actions/historyFoldersActions";
import { useDispatch, useSelector } from "react-redux";

function ModalDeleteFolder({ closeModal, isOpen }) {
  const dispatch = useDispatch();
  const { errorMessage, currentFolder } = useSelector(
    (state) => state.historyFolders
  );

  const onDelete = (e) => {
    dispatch(deleteFolderAction(currentFolder, closeModal));
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      closeModal={closeModal}
      headerText="Delete Folder"
      descriptionText="This action will delete the selected folder, are you sure?"
      onConfirm={onDelete}
      errorMessage={errorMessage}
    />
  );
}

export default ModalDeleteFolder;

import React, { useState } from "react";
import HistoryModal from "./HistoryModal";
import {
  addFolderAction,
  dispatchFolderActionError,
} from "../../Store/Actions/historyFoldersActions";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";

function ModalAddFolder({ closeModal, search, isOpen }) {
  const [modalValue, setModalValue] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => {
    setModalValue("");
    closeModal();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!modalValue.trim()) {
      dispatchFolderActionError(dispatch, "please fill the required feild");
      return;
    }
    dispatch(
      addFolderAction(
        {
          folderName: modalValue,
          folderID: uuid(),
        },
        search,
        handleClose
      )
    );
  };

  const onChangeHandler = (e) => {
    setModalValue(e.target.value);
  };

  return (
    <HistoryModal
      functions={{
        submitHandler,
        handleClose,
        onChangeHandler,
      }}
      texts={{
        title: "Add new folder",
        placeHolder: "folder name",
        buttonText: "Add",
        value: modalValue,
      }}
      isOpen={isOpen}
    />
  );
}

export default ModalAddFolder;

import { createAsyncThunk } from "@reduxjs/toolkit";
import Interceptor from "../API/Interceptor";
const SignUpRequest = createAsyncThunk(
  "signup",
  async ({ user, navigate, setError }, { rejectWithValue }) => {
    try {
      const request = await Interceptor.post(`/signup`, user);
      localStorage.setItem("Authorization", "Bearer " + request.data.token);
      navigate("/generate-resume", { replace: true });
    } catch (e) {
      setError(e.response.data.message);
      return rejectWithValue("Can't Login !!!");
    }
  }
);

export default SignUpRequest;

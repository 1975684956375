import { snackBarAction } from "../Slices/SnackBarSlice";

export const openingSnackBar = (data) => (dispatch) => {
  dispatch(snackBarAction.handleShow(data));
  
};

export const clousingSnackBar = (data) => (dispatch) => {
  dispatch(snackBarAction.handleClouse(data));
};

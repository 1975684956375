import React,{useEffect, useState} from "react";
import Input from "../Common/Input";
import {
  editExperience,
} from "../../Store/Actions";
import Buttons from "../CommonForm/Buttons";
import { useDispatch, useSelector } from "react-redux";
import classes from "../AddExperience/AddExperience.module.css";
export default function EditExperience({ setOpen }) {
  const dispatch = useDispatch();
  const { resume ,employeeId} = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
      employeeId:skillsReducer?.employeeId
    };
  });

  const [experienceData,setExperienceData] = useState({
    title:"",
    company:"",
    startDate:"",
    endDate:""
  })

  useEffect(()=>{
    
    if(resume?.work?.length){
      let experience = resume?.work[employeeId]
      const date = experience?.date?.split('-');
      setExperienceData({
        ...experience,
        startDate:date[0]||"",
        endDate:date[1]||""
      })
    }
    
  },[resume?.work])
  const onChangeHandler = (e) => {
    setExperienceData(prev=>{
      return {...prev,[e.target?.name]:e.target?.value}
    })
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const newData = {
      ...experienceData,
      date:`${experienceData?.startDate} - ${experienceData?.endDate}`
    }
    dispatch(editExperience(employeeId,newData));
    setOpen(false);
  };
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <Input
        text="Title"
        width="487px"
        placeholder="Title"
        type="text"
        value={experienceData?.title}
        onChangeHandler={onChangeHandler}
        name="title"
      />
      <Input
        text="Company"
        width="487px"
        placeholder="Company"
        type="text"
        value={experienceData?.company}
        onChangeHandler={onChangeHandler}
        name="company"
        required={"false"}
      />
      <Input
        text="Start Date"
        width="487px"
        value={experienceData?.startDate}
        placeholder="Start Date"
        type="text"
        onChangeHandler={onChangeHandler}
        name="startDate"
      />
      <Input
        text="End Date"
        value={experienceData?.endDate}
        width="487px"
        placeholder="End Date"
        type="text"
        onChangeHandler={onChangeHandler}
        name="endDate"
      />
      <Buttons
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const style = {
  width: "500px",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  position: "absolute",
  top: "120px",
  left: "50%",
  gap: "16px",
  transform: "translate(-50%, 0%)",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  padding: "24px",
  boxSizing: "border-box",
};

function ConfirmModal({
  isOpen,
  closeModal,
  headerText,
  descriptionText,
  onConfirm,
  children,
  errorMessage,
  confirmText = "Confirm",
  cancelText = "Cancel",
  confirmColor = "error",
  hasButtons = true,
}) {
  return (
    <Modal open={isOpen} onClose={closeModal} closeAfterTransition>
      <Fade in={isOpen}>
        <Box sx={style}>
          {headerText && (
            <Typography fontSize={22} color={"#1D2C34"}>
              {headerText}
            </Typography>
          )}
          {descriptionText && (
            <Typography fontSize={16} color={"#1D2C34"}>
              {descriptionText}
            </Typography>
          )}
          {children}
          {errorMessage && (
            <Typography fontSize={16} color={"error"}>
              {errorMessage}
            </Typography>
          )}
          {hasButtons && (
            <Box width={"100%"} display={"flex"} justifyContent={"end"} gap={1}>
              <Button variant="outlined" onClick={closeModal}>
                {cancelText}
              </Button>
              <Button
                variant="outlined"
                color={confirmColor}
                onClick={onConfirm}
              >
                {confirmText}
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

export default ConfirmModal;

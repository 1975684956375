import React from "react";
import MultiRow from "./MultiRow";
import View from "./View";
import Skills from "./Skills";

function Row({ data, header,clickHandler}) {
  const component = {
    name: <MultiRow data={data.name} width={header.width} />,
    role: <MultiRow data={data.role} width={header.width} />,
    Added: <MultiRow data={data.Added} width={header.width} />,
    Skills: <Skills data={data} width={header.width} />,
    Actions: 
      <View
        data={data}
        width={header.width}
        clickHandler={clickHandler}
      />
    
  };
  return component[header.lable];
}

export default Row;

import { configureStore } from "@reduxjs/toolkit";
import skillsReducer from "./Slices/SkillsSlice";
import snackBarReducer from "./Slices/SnackBarSlice";
import LoginSlice from "./Slices/LoginSlice";
import responsibilitiesReducer from "./Slices/ResponsibilitiesSlice";
import users from "./Slices/users";
import historyFolders from "./Slices/historyFoldersSlice";
import technologies from "./Slices/technologies";
const store = configureStore({
  reducer: {
    skillsReducer,
    snackBarReducer,
    users,
    login: LoginSlice,
    technologies,
    responsibilitiesReducer,
    historyFolders,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

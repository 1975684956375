import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  show: false,
  message: "",
  duration:1000,
  severity:""
};
const snackBarSlice = createSlice({
  name: "snackBarSlice",
  initialState,
  reducers: {
    handleShow(state, action) {
      state.show = true;
      state.message = action?.payload?.message;
      state.severity = action?.payload?.severity
      state.duration = action?.payload?.duration

    },
    handleClouse(state) {
      state.show = false;
      state.message = "";
    },
  },
});
export const snackBarAction = snackBarSlice.actions;

export default snackBarSlice.reducer;

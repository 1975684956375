import React from "react";
import classes from "./input.module.css";

function Input(props) {
  return (
    <div className={classes.searchwrapper}>
      {!props?.img?null:<img className={classes.img} src={props.img} />}
      <input
      ref={props?.ref}
        style={{ width: `${props.width}`, height: `${props?.height}` }}
        onChange={props?.onChangeHandler}
        id={props?.id}
        placeholder={props?.placeholder}
        className={classes.searchinput}
        required
        type={props?.type}
        value={props?.value}
        name={props?.name}
      />
    </div>
  );
}

export default Input;

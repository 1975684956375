import React, { useState } from "react";
import Input from "../Common/Input";
import { useSelector, useDispatch } from "react-redux";
import { editEmpoyeeName } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function EditEmpoyeeName({ setOpen }) {
  const dispatch = useDispatch();
  const { resume } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
    };
  });
  const [employeeName, setEmployeeName] = useState(
    resume?.general?.NAME?.[0] ? resume?.general?.NAME?.[0] : ""
  );
  const onChangeHandler = (e) => {
    setEmployeeName(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault()
    dispatch(editEmpoyeeName(employeeName));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <Input
        text=""
        value={employeeName}
        width="487px"
        placeholder="Edit Employee Name"
        type="text"
        onChangeHandler={onChangeHandler}
        name="employeeName"
      />
      <Buttons
        
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

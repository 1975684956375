import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./adminWrapper.module.css";
import aspireRight from "../../assets/aspireright.png";
import magicLogo from "../../assets/magic-line.svg";
import logOut from "../../assets/logout.svg";
import accountlogo from "../../assets/account.svg";
import histoylogo from "../../assets/history.svg";
import users from "../../assets/usersLogo.svg";
import skills from "../../assets/skills.svg"
const AdminWrapper = (props) => {
  console.log("AdminWrapper");
  const navigate = useNavigate();
  const logOutHandeler = () => {
    localStorage.removeItem("token");
    window.location.reload(false);
  };
  return (
    <div className={classes.container}>
      <section className={classes.leftSection}>
        <img
          className={classes.epicAcademy}
          src={aspireRight}
          alt="epicAcademy"
        />
        <NavLink
          to="/generate-resume"
          className={({ isActive }) =>
            isActive ? classes.buttonToActive : classes.buttonToNotActive
          }
        >
          <img className={classes.logos} src={magicLogo} alt="generateLogo" />
          <p> CV Generator</p>
        </NavLink>
        <NavLink
          to="/history"
          className={({ isActive }) =>
            isActive ? classes.buttonToActive : classes.buttonToNotActive
          }
        >
          <img className={classes.logos} src={histoylogo} alt="usersLogo" />
          <p> History</p>
        </NavLink>
        <NavLink
          to="/users"
          className={({ isActive }) =>
            isActive ? classes.buttonToActive : classes.buttonToNotActive
          }
        >
          <img className={classes.logos} src={users} alt="usersLogo" />
          <p> Users</p>
        </NavLink>
        <NavLink
          to="/skills"
          className={({ isActive }) =>
            isActive ? classes.buttonToActive : classes.buttonToNotActive
          }
        >
          <img className={classes.logos} src={skills} alt="usersLogo" />
          <p> Skills</p>
        </NavLink>
        <NavLink
          to="/responsibilities"
          className={({ isActive }) =>
            isActive ? classes.buttonToActive : classes.buttonToNotActive
          }
        >
          <img className={classes.logos} src={accountlogo} alt="accountLogo" />
          <p> Responsibilities</p>
        </NavLink>
        <p className={classes.profiletext}>Profile</p>
        <NavLink
          to="/account"
          className={({ isActive }) =>
            isActive ? classes.buttonToActive : classes.buttonToNotActive
          }
        >
          <img className={classes.logos} src={accountlogo} alt="accountLogo" />
          <p> Account</p>
        </NavLink>
        <button onClick={logOutHandeler} className={classes.buttonToNotActive}>
          <img className={classes.logos} src={logOut} alt="logOut" />
          <p
            onClick={() => {
              localStorage.removeItem("Authorization");
              navigate("/");
            }}
          >
            Logout
          </p>
        </button>
        
      </section>
      <section className={classes.rightSection}>{props.children}</section>
    </div>
  );
};
export default AdminWrapper;

import React, { useState } from "react";
import Input from "../Common/Input";
import { useSelector, useDispatch } from "react-redux";
import { editTotalExperience } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function EditTotalExperience({ setOpen }) {
  const dispatch = useDispatch();
  const { resume } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
    };
  });
  const [totalExperience, setTotalExperience] = useState(
    resume?.general?.["Total Experience"]
      ? resume?.general?.["Total Experience"]
      : ""
  );
  const onChangeHandler = (e) => {
    setTotalExperience(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault()

    dispatch(editTotalExperience(totalExperience));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  return (
    
      <form style={{ ...style }} onSubmit={saveHandler}>
        <Input
          text=""
          value={totalExperience}
          width="487px"
          placeholder="Edit Employee Speciality"
          type="text"
          onChangeHandler={onChangeHandler}
          name="employeeSpeciality"
        />
        <Buttons
          saveHandler={()=>{}}
          cancelationHandler={() => {
            setOpen(false);
          }}
        />
      </form>
    
  );
}

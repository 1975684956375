// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row_multiRow__0xGdn{\n  height: 44px;\n color: rgba(51, 51, 51, 1);\n margin: 3.5px 0;\n font-family: Roboto;\n font-size: 14px;\n font-weight: 400;\n line-height: 21px;\n letter-spacing: 0em;\n text-align: left;\n display: flex;\n flex-direction: column;\n justify-content: center;\n box-sizing: border-box;\n border-radius: 5px;\n \n }\n .row_skills__i1JON{\ndisplay: flex;\ngap: 8px;\nalign-items: center;\n }\n .row_img__bsMiT{\n  border: 1px solid rgba(232, 232, 238, 1);\nwidth: 40px;\nheight: 40px;\n }", "",{"version":3,"sources":["webpack://./src/Components/Common/row.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;CACb,0BAA0B;CAC1B,eAAe;CACf,mBAAmB;CACnB,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;CAChB,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,sBAAsB;CACtB,kBAAkB;;CAElB;CACA;AACD,aAAa;AACb,QAAQ;AACR,mBAAmB;CAClB;CACA;EACC,wCAAwC;AAC1C,WAAW;AACX,YAAY;CACX","sourcesContent":[".multiRow{\n  height: 44px;\n color: rgba(51, 51, 51, 1);\n margin: 3.5px 0;\n font-family: Roboto;\n font-size: 14px;\n font-weight: 400;\n line-height: 21px;\n letter-spacing: 0em;\n text-align: left;\n display: flex;\n flex-direction: column;\n justify-content: center;\n box-sizing: border-box;\n border-radius: 5px;\n \n }\n .skills{\ndisplay: flex;\ngap: 8px;\nalign-items: center;\n }\n .img{\n  border: 1px solid rgba(232, 232, 238, 1);\nwidth: 40px;\nheight: 40px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiRow": "row_multiRow__0xGdn",
	"skills": "row_skills__i1JON",
	"img": "row_img__bsMiT"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Input from "../Common/Input";
import Modal from "../Users/Modal";

const EditUserModal = (props) => {
  return (
    <Modal
      submitHandler={props?.submitHandler}
      handleClose={props?.handleClose}
      condition={
        props?.generate.name !== "" &&
        props?.generate.email !== "" &&
        props?.generate.role !== ""
      }
      open={props?.open}
      errorMessage={props.errorMessage}
    >
      <Input
        text="Name"
        onChangeHandler={props?.onChangeHandler}
        id="name"
        value={props?.generate.name}
        type="text"
        placeholder="Name"
      ></Input>
      {!props.emailShow ? null : (
        <Input
          text="Email"
          id="email"
          value={props?.generate.email}
          onChangeHandler={props?.onChangeHandler}
          type="email"
          placeholder="Email"
        ></Input>
      )}
      <select
        id="role"
        value={props?.generate?.role}
        onChange={props?.onChangeHandler}
      >
        <option value="">Select Role</option>
        <option value="Admin">Admin</option>
        <option value="Editor">Editor</option>
      </select>
    </Modal>
  );
};

export default EditUserModal;

import React from "react";
import classes from "./row.module.css";
function Skills(props) {
  return (
    <td className={classes.multiRow} style={{ flex: `${props.width}` }}>
      <div className={classes.skills}>
        {props.data.photo && (
          <img className={classes.img} src={props.data.photo} />
        )}
        <p>{props.data.Skills}</p>
      </div>
    </td>
  );
}

export default Skills;

import classes from "./ResumePreview.module.css";
import back from "../../assets/blue-back.svg";
import logo from "../../assets/logo-aspire.svg";
import print from "../../assets/print.svg";
import preview from "../../assets/preview.svg";
import historyPaper from "../../assets/historyPaper.svg";
import PreviewButton from "../../Components/ResumePreview/PreviewButton";
import Resume from "../../Components/Common/Resume";
import { useNavigate } from "react-router-dom";
import GenerateExcel from "../../Requests/GenerateExcel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { skillsAction } from "../../Store/Slices/SkillsSlice";
import { useEffect, useState } from "react";
import { getFoldersAction } from "../../Store/Actions/historyFoldersActions";
import ModalSaveInHistory from "../../Components/History/ModalSaveInHistory";

const ResumePreview = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { arrayOfResponsibilities, resume } = useSelector(
    ({ skillsReducer }) => {
      return {
        arrayOfResponsibilities: skillsReducer?.arrayOfResponsibilities,
        resume: skillsReducer?.resume,
      };
    }
  );
  const generateExcelHandler = () => {
    GenerateExcel(resume, arrayOfResponsibilities, navigate);
  };

  const backToHome = () => {
    dispatch(skillsAction.resetUploadedFile());
    navigate("/generate-resume");
  };

  const printHandler = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const hours = today.getHours();
    const mins = today.getMinutes();
    const name =
      resume?.general?.NAME?.[0] +
      "-" +
      resume?.general?.TITLE?.[0] +
      `-${year}-${month}-${day}-${hours}-${mins}`;
    document.getElementById("title").textContent = name;
    window.print();
  };

  useEffect(() => {
    dispatch(getFoldersAction(""));
  }, []);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const openModal = () => {
    setIsSaveModalOpen(true);
  };
  const closeModal = () => {
    setIsSaveModalOpen(false);
  };

  return (
    <div className={classes["container"]}>
      <div className={classes["back"]}>
        <p className={classes.backpargraph} onClick={backToHome}>
          <img src={back} />
          Back to home
        </p>
      </div>
      <div className={classes["preview-header"]}>
        <img src={logo} />
        <section>
          <PreviewButton
            src={historyPaper}
            btnText="Save in History"
            onClick={openModal}
          />
          <PreviewButton
            src={preview}
            btnText="Download Sheet"
            onClick={generateExcelHandler}
          />
          <PreviewButton
            src={print}
            btnText="Print CV"
            onClick={printHandler}
          />
        </section>
      </div>
      <Resume />
      <ModalSaveInHistory
        isOpen={isSaveModalOpen}
        closeModal={closeModal}
        resume={resume}
      />
    </div>
  );
};
export default ResumePreview;

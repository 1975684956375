import React from "react";
import classes from "./Body.module.css";
import Skill from "../Skills/Skill";
import { addSkillsToEmployee } from "../../Store/Actions/SkillsAction";
import { useDispatch, useSelector } from "react-redux";
export default function ChooseRequiredSkills() {
  const dispatch = useDispatch();
  const [localSkill, setlocalSkills] = React.useState([]);
  const { skills } = useSelector(({ skillsReducer }) => {
    return {
      skills: skillsReducer?.skills,
    };
  });

  const addSkillsHandler = (selectedIndex) => {
    let updatedSkills = localSkill.map((item, index) => {
      if (index === selectedIndex) {
        return { ...item, selected: !item?.selected };
      }
      return { ...item };
    });
    const selectedSkills = updatedSkills?.filter((item) => item?.selected);
    dispatch(addSkillsToEmployee(updatedSkills, selectedSkills));
  };

  const getData = () => {
    return Object.values(
      skills.reduce(
        (acc, item) => ({
          ...acc,
          [item?.label.trim().replaceAll('"', "")]: { ...item },
        }),
        {}
      )
    );
  };

  React.useEffect(() => {
    if (skills) setlocalSkills(getData());
  }, [skills]);

  return (
    <div className={classes.skillWrapper}>
      {localSkill?.map((skill, index) => {
        return (
          <Skill
            index={index}
            skill={skill?.label}
            active={skill?.selected}
            key={skill?.label}
            addSkillsHandler={addSkillsHandler}
          />
        );
      })}
    </div>
  );
}

import React, { useEffect } from "react";
import Input from "../Common/Input";
import {
  createExperience,
  setEmployeeIdHandler,
  fillWorkExperienceData,
} from "../../Store/Actions";
import Buttons from "../CommonForm/Buttons";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import classes from "./AddExperience.module.css";
export default function AddExperience({ setOpen }) {
  const dispatch = useDispatch();

  const { work } = useSelector(({ skillsReducer }) => {
    return {
      work: skillsReducer?.work,
    };
  });

  useEffect(() => {
    dispatch(setEmployeeIdHandler(uuid()));
  }, []);
  const onChangeHandler = (e) => {
    dispatch(
      fillWorkExperienceData({ key: e.target?.name, value: e.target.value })
    );
  };
  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(createExperience());
    setOpen(false);
  };
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <Input
        text="Title"
        width="487px"
        placeholder="Title"
        type="text"
        value={work?.title}
        onChangeHandler={onChangeHandler}
        name="title"
      />
      <Input
        text="Company"
        width="487px"
        placeholder="Company"
        type="text"
        value={work?.company}
        onChangeHandler={onChangeHandler}
        name="company"
        required={"false"}
      />
      <Input
        text="Start Date"
        width="487px"
        value={work?.startDate}
        placeholder="Start Date"
        type="text"
        onChangeHandler={onChangeHandler}
        name="startDate"
      />
      <Input
        text="End Date"
        value={work?.endDate}
        width="487px"
        placeholder="End Date"
        type="text"
        onChangeHandler={onChangeHandler}
        name="endDate"
      />
      <Buttons
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

import classes from "./InputControl.module.css";
const InputControl = (props) => {
  return (
    <div className={classes["input-control"]}>
      <label>{props.label}</label>
      <input
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value || ""}
        required={true}
      />
    </div>
  );
};

export default InputControl;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  responsibilities: {},
};

export const responsibilitiesSlice = createSlice({
  name: "responsibilities",
  initialState,

  reducers: {
    getResponsibilities(state, action) {
      state.responsibilities = action?.payload;
    },
    isLoading() {},
    failed() {},
    addNewResponsibilities(state, { payload }) {
      state.responsibilities.result = [
        ...state?.responsibilities?.result,
        payload,
      ];
    },
    deleteResponsibility(state, action) {
      const newData = state?.responsibilities?.result?.filter(
        (item) => item?.id !== action?.payload?.id
      );
      state.responsibilities.result = newData;
    },
    updateResponsibility(state, { payload }) {
      state.responsibilities.result = state.responsibilities.result.map(
        (item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        }
      );
    },
  },
});
export const responsibilitiesActions = responsibilitiesSlice.actions;

export default responsibilitiesSlice.reducer;

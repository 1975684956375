import React from "react";
import { Pagination } from "@mui/material";
import clasess from "./paginatiom.module.css";
function Paginations(props) {
  const itemPerPage = 5;
  return (
    <>
      <img className={clasess.bline} src={require("../../assets/line.png")} alt=''/>
      <div className={clasess.pagination}>
        <p>
          Showing {props?.current?props?.current:0} out of {props?.count?props?.count:0}
        </p>
        <Pagination
          count={Math.ceil(props.count / itemPerPage)}
          shape="rounded"
          onChange={(e, page) => {
            props.PaginationHandler(page, itemPerPage);
          }}
        />
      </div>
    </>
  );
}

export default Paginations;

import Interceptor from "../../API/Interceptor";
export const getData = async () => {
  try {
    const data = await Interceptor.get(`/get-responsibilities`);
    return data?.data;
  } catch (error) {
    return error;
  }
};
export const getEmployeeRole = async () => {
  try {
    const role = await Interceptor.get(`/get-role-from-marwan`);
    return role;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllResponsibilities = async (limit, offset, search) => {
  try {
    const response = await Interceptor.get(
      `/get-all-responsibilites?limit=${limit}&offset=${
        (offset - 1) * limit
      }&search=${search}`
    );
    return response.data;
  } catch (error) {}
};

export const sendResponsibilitesServices = async (data) => {
  try {
    const response = await Interceptor.post(`/send-responsibilities`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const addNewResponsibilitiesService = async (data) => {
  try {
    return await Interceptor.post("/add-responsibilities", data);
  } catch (error) {
    console.error();
    return error;
  }
};
export const deleteResponsibilityService = async (id) => {
  try {
    return await Interceptor.delete(`/delete-responsibility/${id}`);
  } catch (error) {
    return error;
  }
};
export const getRequest = async (point) => {
  try {
    return await Interceptor.get(`/${point}`);
  } catch (error) {
    return error;
  }
};
export const postRequest = async (point, body) => {
  try {
    return await Interceptor.post(`/${point}`, body);
  } catch (error) {
    return error;
  }
};
export const deleteRequest = async (point) => {
  try {
    return await Interceptor.delete(`/${point}`);
  } catch (error) {
    return error;
  }
};
export const updateRequest = async (point, body) => {
  try {
    return await Interceptor.put(`/${point}`, body);
  } catch (error) {
    return error;
  }
};
export const updateResponsibilityService = async (data) => {
  try {
    return await Interceptor.put("update-responsibility", data);
  } catch (error) {
    return error;
  }
};

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-aspire.svg";
import InputControl from "../../Components/Common/InputControl";
import classes from "./Signup.module.css";
import { useEffect, useState } from "react";
import SignUpRequest from "../../Requests/SignUpRequest";
const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    secretWord: "",
  });

  const setError = (v) => {
    setErrorMessage(v);
  };
  const signUpHandler = (e) => {
    e.preventDefault();
    dispatch(SignUpRequest({ user, navigate, setError }));
  };

  const setFeild = (feild, value) => {
    setUser((prev) => ({ ...prev, [feild]: value }));
  };

  useEffect(() => {
    const token = localStorage.getItem("Authorization");
    if (token) return navigate("/generate-resume");
  }, []);
  return (
    <div className={classes["login-container"]}>
      <section className={classes["login-content"]}>
        <img src={logo} className={classes["logo"]} alt="Not Available" />
        <p className={classes["note"]}>
          * Note that Signup is restricted to Administrators
        </p>
        <form onSubmit={signUpHandler}>
          <InputControl
            label="Name"
            placeholder="Your Name"
            type="text"
            onChange={(e) => {
              setFeild("name", e.target.value);
            }}
            value={user.name}
          />
          <InputControl
            label="Email"
            placeholder="Email"
            type="email"
            onChange={(e) => {
              setFeild("email", e.target.value);
            }}
            value={user.email}
          />
          <InputControl
            label="Password"
            placeholder="Password"
            type="password"
            onChange={(e) => {
              setFeild("password", e.target.value);
            }}
            value={user.password}
          />
          <InputControl
            label="Secret Word for Creating an Account"
            placeholder="Secret Word"
            type="password"
            onChange={(e) => {
              setFeild("secretWord", e.target.value);
            }}
            value={user.secretWord}
          />
          {errorMessage && <p className={classes["error"]}>{errorMessage}</p>}
          <button className={classes["login-btn"]}>SignUp</button>
        </form>
        <p className={classes["copy-right"]}>
          2022 © ASPIRE All rights reserved
        </p>
      </section>
    </div>
  );
};

export default Signup;

import classes from "./resume.module.css";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import color from "../../assets/color.PNG";
import bk from "../../assets/back.PNG";
import bk2 from "../../assets/back2.PNG";
import AddResponsibilityBtn from "./AddResponsibilityBtn";
import aspire from "../../assets/aspire.png";
import RightSection from "../GenerateResume/RightSection";
import Modal from "./Modal";
import { useState } from "react";
import Resizable from "./Resizable";

const Resume = () => {
  const [open, setOpen] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState("");

  const { resume } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
    };
  });

  return (
    <div className={classes.all}>
      <Modal setOpen={setOpen} open={open} typeOfModal={typeOfModal} />
      <div className={classes.header}>
        <div style={{ flex: "1" }}>
          <img className={classes.aspireimage} src={aspire} alt=""></img>
        </div>
        <div style={{ height: "115px", flex: "2.5" }}>
          <h1 className={classes.heading}>
            {resume?.general?.NAME?.[0] ? (
              resume?.general?.NAME?.[0]
            ) : (
              <div className={classes.placeholder}>FirstName LastName</div>
            )}
            <button
              className={classes["btn-edit"]}
              onClick={(e) => {
                setTypeOfModal("editEmpoyeeName");
                setOpen((prev) => !prev);
              }}
            >
              Edit
            </button>
          </h1>
          <h3 className={classes.spicialization}>
            <div className={classes["spicial"]}>
              {resume?.general?.TITLE?.[0] ? (
                resume?.general?.TITLE?.[0]
              ) : (
                <div className={classes.placeholder}> Specialization</div>
              )}
            </div>

            <button
              className={classes["btn-edit"]}
              onClick={(e) => {
                setTypeOfModal("editEmployeeٍٍٍSpecialty");
                setOpen((prev) => !prev);
              }}
            >
              Edit
            </button>
          </h3>
        </div>
      </div>
      <div className={classes.resumecontainer}>
        <div className={classes.leftside}>
          <img src={color} className={classes.gradualcolor} />

          <div className={classes.experience}>
            <h3 className={classes.totalexperience}>Total Experience</h3>
            <p className={classes.numberexperience}>
              <img src={bk} className={classes.graybk} alt="" />
              <span style={{ zIndex: "2" }}>
                {resume?.general?.["Total Experience"]}
              </span>
              <button
                className={classes["btn-edit"]}
                style={{ zIndex: "2" }}
                onClick={(e) => {
                  setTypeOfModal("editTotalExperience");
                  setOpen((prev) => !prev);
                }}
              >
                Edit
              </button>
            </p>
          </div>
          <img src={color} className={classes.gradualcolor} alt="" />
          <div className={classes.grayboxs}>
            <img src={bk} className={classes.graybk} alt="" />
            <div
              className={
                resume?.general?.SUMMARY?.length
                  ? classes.grybox
                  : classes.hidegrybox
              }
            >
              {/* <img src={bk} className={classes.graybk} alt="" /> */}
              <h2 className={classes.title}>
                SUMMARY{" "}
                <button
                  className={classes["btn-edit"]}
                  onClick={(e) => {
                    setTypeOfModal("editSummary");
                    setOpen((prev) => !prev);
                  }}
                >
                  Edit
                </button>
              </h2>
              {resume?.general?.SUMMARY?.length ? null : (
                <div className={classes.placeholder}>Write Summary</div>
              )}
              <Resizable>
                {resume?.general?.SUMMARY?.map((item) => {
                  return (
                    <p key={uuid()} className={classes.data}>
                      {item}
                    </p>
                  );
                })}
              </Resizable>
            </div>
            <div
              className={
                resume?.general?.["EDUCATION & CERTIFICATE"]?.length
                  ? classes.grybox
                  : classes.hidegrybox
              }
            >
              <div
                style={{
                  zIndex: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 className={classes.title}>EDUCATION & CERTIFICATE</h2>
                <button
                  className={classes["btn-edit"]}
                  onClick={(e) => {
                    setTypeOfModal("editEducation");
                    setOpen((prev) => !prev);
                  }}
                >
                  Edit
                </button>
              </div>
              <Resizable>
                {resume?.general?.["EDUCATION & CERTIFICATE"]?.map((item) => {
                  return (
                    <p key={uuid()} className={classes.data}>
                      {item}
                    </p>
                  );
                })}
              </Resizable>

              <AddResponsibilityBtn
                btnText="Add Education"
                function={() => {
                  setTypeOfModal("AddEducation");
                  setOpen((prev) => !prev);
                }}
              />
            </div>
            <div
              className={
                resume?.general?.["LANGUAGES"]?.length
                  ? classes.grybox
                  : classes.hidegrybox
              }
            >
              <div
                style={{
                  zIndex: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 className={classes.title}>LANGUAGES</h2>
                <button
                  className={classes["btn-edit"]}
                  onClick={(e) => {
                    setTypeOfModal("editLanguage");
                    setOpen((prev) => !prev);
                  }}
                >
                  Edit
                </button>
              </div>
              <Resizable>
                {resume?.general?.["LANGUAGES"]?.map((item) => {
                  return (
                    <p key={uuid()} className={classes.data}>
                      {item}
                    </p>
                  );
                })}
              </Resizable>

              <AddResponsibilityBtn
                btnText="Add language"
                function={() => {
                  setTypeOfModal("AddLanguage");
                  setOpen((prev) => !prev);
                }}
              />
            </div>
            <div
              className={
                resume?.general?.["TECHNOLOGIES & SKILLS"]?.length
                  ? classes.grybox
                  : classes.hidegrybox
              }
            >
              {/* <img src={bk} className={classes.graybk} alt="" /> */}
              <div
                style={{
                  zIndex: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 className={classes.title}>TECHNOLOGIES & SKILLS</h2>
                <button
                  className={classes["btn-edit"]}
                  onClick={(e) => {
                    setTypeOfModal("editTechnologiesSkills");
                    setOpen((prev) => !prev);
                  }}
                >
                  Edit
                </button>
              </div>

              <Resizable>
                <div className={classes.TechAndSkillCon}>
                  {resume?.general?.["TECHNOLOGIES & SKILLS"]?.map((item) => {
                    return item.split(",").map((element) => {
                      return (
                        <div className={classes.TechAndSkill}>
                          <img
                            src={bk2}
                            className={classes.TechAndSkillbk}
                            alt=""
                          />
                          <p key={uuid()}>{element}</p>
                        </div>
                      );
                    });
                  })}
                </div>
              </Resizable>
            </div>
          </div>
        </div>
        <RightSection data={resume} />
      </div>

      <footer className={classes.footer}>
        <div style={{ flex: "1" }}>
          <img src={color} className={classes.gradualcolor} />
          <p className={classes.candidate}>ASPIRE CANDIDATE</p>
        </div>
        <p className={classes.text}>aspire.jo</p>
      </footer>
    </div>
  );
};
export default Resume;

import React from "react";
import classes from "./rightsection.module.css";
import { v4 as uuid } from "uuid";
function Technologies(props) {
  return (
    <div
      className={props?.item?.length ? classes.tech : classes.techhide}
      style={{ width: "140px" }}
    >
      <h4 className={classes.techheader}>Technologies/Skills</h4>
      <ul>
        {props?.item?.map((item) => {
          return (
            item.split(",").map((element) => {
              return (
                <li key={uuid()} className={classes.data}>
              {element}
            </li>
              );
            })
           
          );
        })}
      </ul>
    </div>
  );
}

export default Technologies;

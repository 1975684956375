// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__mtodi {\n  /* width: 17.9%; */\n  gap: 24px;\n  height: 72px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.Header_header__mtodi > span {\n  width: 179px;\n  height: 44px;\n\n  font-family: \"Montserrat\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 22px;\n\n  color: #000000;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,YAAY;;EAEZ,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;EAEjB,cAAc;AAChB","sourcesContent":[".header {\n  /* width: 17.9%; */\n  gap: 24px;\n  height: 72px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.header > span {\n  width: 179px;\n  height: 44px;\n\n  font-family: \"Montserrat\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 22px;\n\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__mtodi"
};
export default ___CSS_LOADER_EXPORT___;

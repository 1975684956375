import React from "react";
import classes2 from "./button.module.css";
function Button(props) {
  return (
    <button
      onClick={props.handler}
      className={props.img ? classes2.submitBtn : classes2.submitBtn}
      style={props.style}
    >
      {props?.img && <img src={props?.img} alt="" />} <p>{props.text}</p>
    </button>
  );
}

export default Button;

import axios from "axios";
const Interceptor = axios.create({
  baseURL: process.env.REACT_APP_URL,
});
Interceptor.interceptors.request.use(
  (request) => {
    request.headers["Authorization"] = localStorage.getItem("Authorization");
    return request;
  },
  error => { 
     return Promise.reject(error);
});

Interceptor.interceptors.response.use(
  (response) => {   
    console.log(response);
    return response
  },
  error => { 
    if(error.response.status==401){
      localStorage.removeItem("Authorization")
      window.location.href=window.location.origin
    }
     return Promise.reject(error);
})
export default Interceptor;


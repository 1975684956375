import Interceptor from "../API/Interceptor";
const GenerateExcel = async (resume, arrayOfResponsibilities, navigate) => {
  Interceptor.post(
    `/generate-excel`,
    { resume, arrayOfResponsibilities },
    {
      responseType: "blob",
    }
  )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const hours=today.getHours()
      const mins=today.getMinutes()
      const name =
        resume?.general?.NAME?.[0] +
        "-" +
        resume?.general?.TITLE?.[0] 
     + `-${year}-${month}-${day}-${hours}-${mins}`
        
      link.setAttribute("download", `${name || "unknown"}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((e) => {
      console.log("Error!!");
    });
};
export default GenerateExcel;

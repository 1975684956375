import React from "react";
import Box from "@mui/material/Box";
import Modalui from "@mui/material/Modal";
import classes from "./modal.module.css";
import close from "../../assets/close.svg";
import { Input } from "@mui/material";
import { useSelector } from "react-redux";

const style = {
  width: "500px",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  position: "absolute",
  top: "120px",
  left: "50%",
  gap: "32px",
  transform: "translate(-50%, 0%)",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  padding: "24px",
  boxSizing: "border-box",
};

const HistoryModal = ({ functions, texts, isOpen }) => {
  const { submitHandler, handleClose, onChangeHandler } = functions;
  const { title, placeHolder, buttonText, value } = texts;
  const { loading, errorMessage } = useSelector(
    (state) => state.historyFolders
  );

  const isLoading = loading;
  return (
    <Modalui
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div className={classes.heading}>
          <h3>{title}</h3>
          <button onClick={handleClose}>
            <img className={classes.close} src={close} alt="" />
          </button>
        </div>
        <form onSubmit={submitHandler}>
          <div className={classes.inputs}>
            <Input
              value={value}
              type="text"
              placeholder={placeHolder}
              onChange={onChangeHandler}
              required
            />
            {errorMessage && (
              <p className={classes.errorMessage}>{errorMessage}</p>
            )}
            <div className={classes.submitBtnCon}>
              <button
                type="submit"
                disabled={isLoading}
                className={`${classes.submitBtn} ${
                  isLoading ? classes.disableBtn : classes.activeBtn
                }`}
              >
                {isLoading ? <p>Loading...</p> : <p>{buttonText}</p>}
              </button>
            </div>
          </div>
        </form>
      </Box>
    </Modalui>
  );
};
export default HistoryModal;

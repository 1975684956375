import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {deleteProjectAction} from '../../Store/Actions'
export default function DeleteProject({setOpen=()=>{}}) {
const dispatch = useDispatch()
const {employeeId} = useSelector(({skillsReducer})=>{
  return {
    employeeId:skillsReducer?.employeeId
  }
})
const deleteHandler = ()=>{
dispatch(deleteProjectAction(employeeId));
setOpen(false)
}
  return (
    <button onClick={deleteHandler}>Confirm</button>
    
  )
}

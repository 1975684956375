import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { getAllTechnologiesAction } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function AddTechnology({ setOpen, addTechnology }) {
  const [technology, setTechnology] = useState([]);
  const [SelectedTech, setSelectedTech] = useState("");

  useEffect(() => {
    getAllTechnologiesAction()
      .then((res) => {
        setTechnology(res.result);
      })
      .catch((err) => {});
  }, []);
  const dispatch = useDispatch();
  const { experienceId } = useSelector(({ skillsReducer }) => {
    return {
      experienceId: skillsReducer?.experienceId,
    };
  });

  const onChangeHandler = (e) => {
    setSelectedTech(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault();
    if (SelectedTech !== "") {
      console.log(SelectedTech, experienceId);
      dispatch(addTechnology(SelectedTech, experienceId));
    }
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <FormControl style={{ width: "503px" }}>
        <Select
          defaultValue=""
          onChange={onChangeHandler}
          displayEmpty
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontSize: 16,
            color: "#000000",
            fontWeight: "500",
            borderRadius: "8px",
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: 16,
              color: "#000000",
              fontWeight: "500",
            }}
            value={""}
          >
            {"Select Technology"}
          </MenuItem>
          {technology?.map((element, index) => {
            return (
              <MenuItem
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontSize: 16,
                  color: "#000000",
                  fontWeight: "500",
                }}
                name={`${element}`}
                value={`${element}`}
                key={`${element}`}
              >
                {element}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Buttons
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

import React, { useState } from "react";
import Input from "../Common/Input";
import { useSelector, useDispatch } from "react-redux";
import { editEmpoyeeSpeciality } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function EditEmployeeSpeciality({ setOpen }) {
  const dispatch = useDispatch();
  const { resume } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
    };
  });
  const [employeeSpeciality, setEmployeeSpeciality] = useState(
    resume?.general?.TITLE?.[0] ? resume?.general?.TITLE?.[0] : ""
  );
  const onChangeHandler = (e) => {
    setEmployeeSpeciality(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault()

    dispatch(editEmpoyeeSpeciality(employeeSpeciality));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  return (
   
      <form style={{ ...style }} onSubmit={saveHandler}>
        <Input
          
          value={employeeSpeciality}
          width="487px"
          placeholder="Edit Employee Speciality"
          type="text"
          onChangeHandler={onChangeHandler}
          name="employeeSpeciality"
        />
        <Buttons
          saveHandler={()=>{}}
          cancelationHandler={() => {
            setOpen(false);
          }}
        />
      </form>
    
  );
}

import React from "react";
import Skill from "../Skills/Skill";
import classes from "./JobResponsibilities.module.css";

export default function ResponsibilitieCard({
  id,
  skill = [],
  description,
  index,
  selectResponsibilites,
  checked,
}) {
 
  return (
    <div
      className={classes.responsibilitieCard}
      style={{ background: checked ? "#F6F6F6" : "" }}
    >
      <div className={classes.descriptionWrapper}>
        <input
          type="checkbox"
          value={checked}
          // onChange={(e) => {
          //   selectResponsibilites(e, description);
          // }}
        />
        {index}.<ul>{description}</ul>
      </div>
      <div className={classes.cardFooter}>
        {skill?.map((item) => {
          return <Skill skill={item} active={false} cursor="auto" key={item}/>;
        })}
      </div>
    </div>
  );
}

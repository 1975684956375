// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewButton_preview-btn__chpP9 {\n  display: flex;\n  padding: 16px 18px;\n  box-sizing: border-box;\n  align-items: center;\n  background-color: transparent;\n  border: 2px solid #1d5fe0;\n  border-radius: 8px;\n  margin-left: 24px;\n  cursor: pointer;\n}\n.PreviewButton_preview-btn__chpP9 img {\n  width: 24px;\n  height: 24px;\n  margin-right: 9px;\n}\n.PreviewButton_preview-btn__chpP9 div {\n  color: #1d5fe0;\n  font-size: 16px;\n  font-family: \"Montserrat\";\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/ResumePreview/PreviewButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".preview-btn {\n  display: flex;\n  padding: 16px 18px;\n  box-sizing: border-box;\n  align-items: center;\n  background-color: transparent;\n  border: 2px solid #1d5fe0;\n  border-radius: 8px;\n  margin-left: 24px;\n  cursor: pointer;\n}\n.preview-btn img {\n  width: 24px;\n  height: 24px;\n  margin-right: 9px;\n}\n.preview-btn div {\n  color: #1d5fe0;\n  font-size: 16px;\n  font-family: \"Montserrat\";\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview-btn": "PreviewButton_preview-btn__chpP9"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {deleteExperienceAction} from '../../Store/Actions'
export default function DeleteExperience({setOpen=()=>{}}) {
const dispatch = useDispatch()
const {employeeId} = useSelector(({skillsReducer})=>{
  return {
    employeeId:skillsReducer?.employeeId
  }
})
const deleteExperienceHandler = ()=>{
dispatch(deleteExperienceAction(employeeId));
setOpen(false)
}
  return (
    <button onClick={deleteExperienceHandler}>Confirm</button>
    
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paginatiom_pagination__Gv1gS {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  margin: 38px 0px 21px 0px;\n}\n.paginatiom_pagination__Gv1gS p {\n  font-family: Roboto;\n  font-style: normal;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  position: absolute;\n  left: 20px;\n  color: rgba(105, 105, 105, 1);\n}\n.paginatiom_bline__YuKhR {\n  box-sizing: border-box;\n  padding:0px 21.8px;\n  width: 100%;\n  height: 2px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Common/paginatiom.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,6BAA6B;AAC/B;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb","sourcesContent":[".pagination {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  margin: 38px 0px 21px 0px;\n}\n.pagination p {\n  font-family: Roboto;\n  font-style: normal;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  position: absolute;\n  left: 20px;\n  color: rgba(105, 105, 105, 1);\n}\n.bline {\n  box-sizing: border-box;\n  padding:0px 21.8px;\n  width: 100%;\n  height: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": "paginatiom_pagination__Gv1gS",
	"bline": "paginatiom_bline__YuKhR"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddResponsibilityBtn_btn-container__M1gAU{\n    display: flex;\n    align-items: center;\n    position: relative;\n    z-index: 2;\n    background-color: transparent;\n    border: none;\n    outline: none;\n    cursor: pointer;\n    opacity: 1!important;\n}\n.AddResponsibilityBtn_btn-container__M1gAU img{\n    width: 14px;\n    height: 14px;\n}\n.AddResponsibilityBtn_btn-container__M1gAU p{\n    margin: 0 0 0 8px;\n    color:#1D5FE0;\n    font-family: \"Montserrat\";\n    font-size: 16px;\n    font-weight: 500;\n}\n@media print {\n    .AddResponsibilityBtn_btn-container__M1gAU{\n        display: none;\n        margin: 0;\n    }\n}", "",{"version":3,"sources":["webpack://./src/Components/Common/AddResponsibilityBtn.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI;QACI,aAAa;QACb,SAAS;IACb;AACJ","sourcesContent":[".btn-container{\n    display: flex;\n    align-items: center;\n    position: relative;\n    z-index: 2;\n    background-color: transparent;\n    border: none;\n    outline: none;\n    cursor: pointer;\n    opacity: 1!important;\n}\n.btn-container img{\n    width: 14px;\n    height: 14px;\n}\n.btn-container p{\n    margin: 0 0 0 8px;\n    color:#1D5FE0;\n    font-family: \"Montserrat\";\n    font-size: 16px;\n    font-weight: 500;\n}\n@media print {\n    .btn-container{\n        display: none;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-container": "AddResponsibilityBtn_btn-container__M1gAU"
};
export default ___CSS_LOADER_EXPORT___;

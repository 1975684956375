import React, { useState } from "react";
import Input from "../Common/Input";
import { useDispatch } from "react-redux";
import { addEducation } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function AddEducation({ setOpen }) {
  const dispatch = useDispatch();

  const [education, setEducation] = useState("");
  const onChangeHandler = (e) => {
    setEducation(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault()

    dispatch(addEducation(education));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap:"24px",
    flexDirection: "column",
  }
  return (
    <>
    <form style={{...style}} onSubmit={saveHandler}>
      <Input
        text=""
        value={education}
        width="487px"
        placeholder="Add Education & Certificate"
        type="text"
        onChangeHandler={onChangeHandler}
        name="addeduaction"
      />
      
      <Buttons saveHandler={()=>{}} cancelationHandler={()=>{setOpen(false)}}/>
    </form>
    </>
  );
}

import React, { Fragment } from "react";
import clasess from "./table.module.css";
import { v4 as uuidv4 } from "uuid";
import upDown from "../../assets/upDown.svg";
import line from "../../assets/line.png";
import { Button } from "@mui/material";
const Table = ({ data, clickHandler }) => {
  return (
    <table className={clasess.table}>
      {data.map((element, i) => {
        return (
          <tbody key={uuidv4()}>
            {i === 0 && (
              <tr>
                {element?.map((element) => {
                  return (
                    <th key={uuidv4()} style={{ flex: `${element.width}` }}>
                      {element.lable !== "" && (
                        <img className={clasess.upDown} src={upDown} alt="" />
                      )}
                      <p>{element.lable}</p>
                    </th>
                  );
                })}
              </tr>
            )}
            {i !== 0 && (
              <Fragment key={uuidv4()}>
                <tr className={clasess.line}>
                  <td className={clasess.line}>
                    <img className={clasess.line} src={line} alt="" />
                  </td>
                </tr>
                <tr key={uuidv4()}>
                  {data[0]?.map((header, i) => {
                    return (
                      <Row
                        key={uuidv4()}
                        header={header}
                        data={element}
                        clickHandler={clickHandler}
                      />
                    );
                  })}
                </tr>
              </Fragment>
            )}
          </tbody>
        );
      })}
    </table>
  );
};

export default Table;

function Row({ data, header, clickHandler }) {
  const component = {
    name: <MultiRow data={data.name} width={header.width} />,
    role: <MultiRow data={data.role} width={header.width} />,
    Actions: (
      <View data={data} width={header.width} clickHandler={clickHandler} />
    ),
  };
  return component[header.lable];
}

function MultiRow(props) {
  return (
    <td className={clasess.multiRow} style={{ flex: `${props.width}` }}>
      <p>{props.data}</p>
    </td>
  );
}

function View({ data, clickHandler, width }) {
  return (
    <td className={clasess.actionsContainer} style={{ flex: `${width}` }}>
      <div>
        <Button
          id={data?.id}
          color="error"
          onClick={() => {
            clickHandler?.deleteHandler(data);
          }}
        >
          Delete
        </Button>
        <Button
          id={data?.id}
          color="info"
          onClick={() => {
            clickHandler?.viewHandler(data);
          }}
        >
          View
        </Button>
      </div>
    </td>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  errorMessage: "",
  currentFolder: {
    folderName: "",
    folderID: "",
  },
};

export const historyFoldersSlice = createSlice({
  name: "historyFolders",
  initialState,
  reducers: {
    save: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.data = payload.result;
    },
    delete: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.data = state.data.filter(
        (folder) => folder.folderID !== payload.folderID
      );
    },
    add: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.data = [...state.data, payload];
    },

    edit: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.data = state.data.map((folder) => {
        if (folder.folderID === payload.folderID) {
          return payload;
        }
        return folder;
      });
    },
    startLoading: (state) => {
      state.loading = true;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload;
    },
    setCurrentFolder: (state, { payload }) => {
      state.loading = false;
      state.currentFolder = payload;
    },
  },
});
export const historyFoldersActions = historyFoldersSlice.actions;

export default historyFoldersSlice.reducer;

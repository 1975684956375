import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editLanguage } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function EditLanguage({ setOpen }) {
  const dispatch = useDispatch();
  
  const { resume } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
    };
  });
  const [education, setEducation] = useState((prev) => {
    const summary = resume?.general?.["LANGUAGES"]
      ? resume?.general?.["LANGUAGES"]?.join("\n")
      : "";
    return summary;
  });
  const onChangeHandler = (e) => {
    setEducation(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault();
    const afterEdit = education?.split("\n");
    const newArr = afterEdit?.reduce((acc,item)=>{
      if(item !==''){
        return [...acc,item]
      }else{
        return [...acc]
      }
    },[])
    dispatch(editLanguage(newArr));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  const textareaStyle = {
    width: "503px",
    height: "118px",
    padding: "19px 16px",
    boxSizing: "border-box",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    resize: "none",
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",

    color: "#1D2C34",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <textarea
        placeholder="Edit Language"
        value={education}
        onChange={onChangeHandler}
        style={{ ...textareaStyle }}
      />

      <Buttons
        saveHandler={() => {}}
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

import { createSlice } from "@reduxjs/toolkit";
const LoginSlice = createSlice({
  name: "login",
  initialState: {
    email:"",
    password:"",
    status:''
  },
  reducers: {
    userEmail(state,action){
      state.email=action.payload
    },
    userPassword(state,action){
      state.password=action.payload        
    },
  },

  extraReducers:(builder)=>{
    builder.addCase('login/rejected',(state,action)=>{state.status='Incorrect Email Or Password'})
    builder.addCase('login/fulfilled',(state,action)=>{state.status=''})
  }
});

export const loginActions=LoginSlice.actions
export default LoginSlice.reducer
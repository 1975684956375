import React from "react";
import InputTypeSelect from "../Common/InputTypeSelect";
import GeneratButton from "../Common/GeneratButton";
import classes from "./Body.module.css";
import { useDispatch, useSelector } from "react-redux";
import magicLogo from "../../assets/magic-line.svg";
import listSettingsLine from "../../assets/list-settings-line.svg";
import ChooseRequiredSkills from "./ChooseRequiredSkills";
import { getAllData } from "../../Store/Actions";

export default function Body({ genrateHandler, view }) {
  const dispatch = useDispatch();
  const { employeeSkills, } = useSelector(({ skillsReducer }) => {
    return {
      employeeSkills: skillsReducer?.employeeSkills,
    };
  });

  React.useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);
  return (
    <div className={classes.body}>
      <InputTypeSelect view={view} />
      <ChooseRequiredSkills />
      <GeneratButton
        genrateHandler={genrateHandler}
        active={employeeSkills?.length}
        title={"Generate Responsibilities"}
        icon={employeeSkills?.length ? listSettingsLine : magicLogo}
      />
    </div>
  );
}

import React from 'react';
import Input from '../Common/Input';
import Modal from '../Users/Modal';

const SkillsModal=(props)=> {
  return (
    <Modal
    submitHandler={props?.submitHandler}
      handleClose={props?.handleClose}
      condition={props?.generate?.Skills !== "" }
      open={props?.open}
    >
      <Input
              text="Skill"
              id="Skills"
              value={props?.generate?.Skills}
              onChangeHandler={props?.onChangeHandler}
              type="text"
              placeholder="Skill"
            ></Input>
            <Input
              text="icon"
              onChangeHandler={props?.onChangeHandler}
              id="photo"
              value={props?.generate?.photo}
              type="text"
              placeholder="icon Url"
            ></Input>
            
    </Modal>
  );
}

export default SkillsModal ;
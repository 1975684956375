import React, { useEffect, useState } from "react";
import AdminWrapper from "../../Components/Common/AdminWrapper";
import Header from "../../Components/Common/Header";
import Table from "../../Components/Common/Table";
import Pagination from "../../Components/Common/Pagination";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../Components/Users/EditUserModal";
import {
  getUsersAction,
  deleteUserAction,
  editUserAction,
} from "../../Store/Actions/users";
import { inviteUserAction } from "../../Store/Actions/users";
import { usersActions } from "../../Store/Slices/users";
import ConfirmModal from "../../Components/Common/ConfirmModal";

const Users = (props) => {
  const [search, setSearch] = useState("");
  const [generate, setGenerate] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [inviteOpen, setInviteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const handleCloseInvite = () => {
    setInviteOpen(false);
    setGenerate({
      name: "",
      email: "",
      role: "",
    });
    dispatch(usersActions.setError(""));
  };
  const handleCloseEdit = () => {
    setEditOpen(false);
    setGenerate({
      name: "",
      email: "",
      role: "",
    });
    dispatch(usersActions.setError(""));
  };
  const { users, errorMessage } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const getUsers = async (page = 1, limit = 5, searchString = search) => {
    dispatch(getUsersAction(page, limit, searchString));
  };
  useEffect(() => {
    getUsers(1, 5);
  }, []);

  const editHandler = async (id) => {
    setEditOpen(true);
    const user = users?.result.find((user) => user.id === id);
    setGenerate(user);
  };

  const handleOpen = () => {
    setInviteOpen(true);
  };

  const onChangeHandler = ({ target }) => {
    setGenerate((state) => {
      return { ...state, [target.id]: target.value };
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      dispatch(inviteUserAction(generate, search, handleCloseInvite));
    } catch (error) {
      console.log(error);
    }
  };
  const searchHandler = (e) => {
    const searchValue = e.target?.value;

    setSearch(searchValue);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      getUsers(1, 5, searchValue);
    }, 1000);
    setTimer(newTimer);
  };
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(editUserAction(generate, handleCloseEdit));
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedID, setDeletedID] = useState(null);
  const openModalDelete = (id) => {
    setDeleteModal(true);
    setDeletedID(id);
  };
  const closeModalDelete = () => {
    setDeleteModal(false);
  };
  const onConfirmDelete = async () => {
    dispatch(deleteUserAction(deletedID));
    closeModalDelete();
  };

  return (
    <AdminWrapper>
      <Header
        text={{ button: "Add new user", heading: "Users" }}
        count={`${users.count} ${users.count > 1 ? "Users" : "User"}`}
        handler={handleOpen}
        placeholder="Search user"
        onChangeHandler={searchHandler}
      />

      <Table
        to="/"
        data={users?.result}
        flex="1.5"
        clickHandler={{ deleteHandler: openModalDelete, editHandler }}
      />

      <Pagination
        count={users.count}
        current={users.result.length - 1}
        PaginationHandler={getUsers}
      />
      <Modal
        open={inviteOpen}
        emailShow={true}
        submitHandler={submitHandler}
        generate={generate}
        handleClose={handleCloseInvite}
        onChangeHandler={onChangeHandler}
        errorMessage={errorMessage}
      />
      <Modal
        open={editOpen}
        emailShow={false}
        submitHandler={editSubmitHandler}
        generate={generate}
        handleClose={handleCloseEdit}
        onChangeHandler={onChangeHandler}
      />

      <ConfirmModal
        isOpen={deleteModal}
        closeModal={closeModalDelete}
        headerText="Delete User"
        descriptionText="This action will delete the selected user, are you sure?"
        onConfirm={onConfirmDelete}
      />
    </AdminWrapper>
  );
};

export default Users;

import classes from "./Skill.module.css";
import React from "react";

export default function Skill({
  skill = "",
  active = true,
  addSkillsHandler = () => {},

  cursor = "pointer",
  index,

  id,
}) {
  return (
    <div
      className={active ? classes.skillActive : classes.skillInactive}
      style={{ cursor }}
      onClick={() => {
        addSkillsHandler(index);
      }}
    >
      <span className={classes.span}>{skill}</span>
    </div>
  );
}

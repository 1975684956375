import React, { useState } from "react";
import {  useDispatch } from "react-redux";
import { AddProjectAction } from "../../Store/Actions";
import { v4 as uuidv4 } from "uuid";
import Input from "../Common/Input";
import Buttons from "./Buttons";
export default function AddProject({ setOpen = () => {} }) {
  const dispatch = useDispatch();
 

  const [project, setProject] = useState({
    title: "",
    description: "",
  });
  const onChangeHandlerForTitle = (e) => {
    setProject(prev=>{
      return {...prev,title:e.target?.value}
    });
  };
  const onChangeHandlerForDescription = (e) => {
    setProject(prev=>{
      return {...prev,description:e.target?.value}
    });
  };
  const saveHandler = (e) => {
    e.preventDefault();
    
    const newData = {
      id:uuidv4(),
      DESCRIPTION:project?.description?.split("\n"),
      NAME:[`${project?.title}`],
      TECHNOLOGIES:[],
    }
    dispatch(AddProjectAction(newData));
    setOpen(false);
  };
  
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  const textareaStyle = {
    width: "503px",
    height: "118px",
    padding: "19px 16px",
    boxSizing: "border-box",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    resize: "none",
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1D2C34",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <Input
        text=""
        value={project?.title}
        width="487px"
        placeholder="Title"
        type="text"
        onChangeHandler={onChangeHandlerForTitle}
        name="title"
      />
      <textarea
        placeholder="Description"
        value={project?.description}
        onChange={onChangeHandlerForDescription}
        style={{ ...textareaStyle }}
        name="description"
      />
      <Buttons
        saveHandler={() => {}}
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

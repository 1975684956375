import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import ConfirmModal from "../Common/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { addHistoryAction } from "../../Store/Actions/histories";
export default function ModalSaveInHistory({ closeModal, isOpen, resume }) {
  const [selectedFolder, setSelectedFolder] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { data } = useSelector((state) => state.historyFolders);
  const onChangeHandler = (e) => {
    setSelectedFolder(e.target?.value);
  };

  const closeHandler = () => {
    closeModal();
    setSelectedFolder("");
    setErrorMessage("");
  };

  const saveHandler = (e) => {
    if (!selectedFolder.trim()) {
      setErrorMessage("You must select Folder Name");
      return;
    }
    if (!data.some((folder) => folder.folderID === selectedFolder)) {
      setErrorMessage("Your Selection went wrong, try to refresh the page");
      return;
    }

    addHistoryAction(
      {
        folderID: selectedFolder,
        name: resume.general.NAME,
        role: resume.general.TITLE,
        resume,
      },
      closeHandler
    );
  };

  const navigate = useNavigate();

  return (
    <ConfirmModal
      isOpen={isOpen}
      closeModal={closeHandler}
      onConfirm={saveHandler}
      hasButtons={data.length !== 0}
      errorMessage={errorMessage}
      confirmText={"Save"}
      confirmColor={"success"}
    >
      {data.length === 0 ? (
        <div>
          <h5>
            Yout dont have any folder yet, create one from 👉{" "}
            <Button
              variant="text"
              onClick={() => {
                navigate("/history");
              }}
              sx={{
                textDecoration: "underline",
              }}
            >
              History
            </Button>
          </h5>
        </div>
      ) : (
        <>
          <h5>
            Select folder name from below folders name to save the resume in
          </h5>
          <FormControl>
            <Select
              defaultValue=""
              onChange={onChangeHandler}
              displayEmpty
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: 16,
                color: "#000000",
                fontWeight: "500",
                borderRadius: "8px",
              }}
            >
              <MenuItem
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontSize: 16,
                  color: "#000000",
                  fontWeight: "500",
                }}
                value={""}
              >
                {"Select Folder"}
              </MenuItem>
              {data?.map((element) => {
                return (
                  <MenuItem
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontSize: 16,
                      color: "#000000",
                      fontWeight: "500",
                    }}
                    name={`${element.folderID}`}
                    value={`${element.folderID}`}
                    key={`${element.folderID}`}
                  >
                    {element.folderName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </>
      )}
    </ConfirmModal>
  );
}

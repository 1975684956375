import classes from "./PreviewButton.module.css";
const PreviewButton = (props) => {
  return (
    <button className={classes["preview-btn"]} onClick={props.onClick}>
      <img src={props.src} />
      <div>{props.btnText}</div>
    </button>
  );
};

export default PreviewButton;

import { skillsAction } from "../Slices/SkillsSlice";
import { getData, sendResponsibilitesServices } from "../Services";

export const getAllData = () => async (dispatch) => {
  try {
    const data = await getData();

    const allRoles = Object.values(
      data?.reduce((acc, item) => ({ ...acc, [item?.role]: item?.role }), {})
    );

    const tagsByRole = data?.reduce((acc, item) => {
      if (acc[item?.role]) {
        return {
          ...acc,
          [item?.role]: [
            ...acc[item?.role],
            item?.category?.map((subItem) => ({
              label: subItem,
              selected: false,
              responsibility: item?.responsibility,
            })),
          ].flat(),
        };
      }
      return {
        ...acc,
        [item?.role]: item?.category
          ?.map((subItem) => ({
            label: subItem,
            selected: false,
            responsibility: item?.responsibility,
          }))
          .flat(),
      };
    }, {});
    dispatch(skillsAction?.getRole({ allRoles, data, tagsByRole }));
  } catch (error) {}
};

export const getSkillsData =
  (employeeRole, tagsByRole, arrayOfTechnology) => (dispatch) => {
    let defualtEmployeeSkillsArray = [];
    arrayOfTechnology?.forEach((item) => {
      item?.TECHNOLOGIES?.forEach((element) => {
        defualtEmployeeSkillsArray.push(element);
      });
    });

    const data = tagsByRole[employeeRole]?.map((item) => {
      if (defualtEmployeeSkillsArray?.includes(item?.label)) {
        return {
          ...item,
          selected: true,
        };
      } else {
        return item;
      }
    });
    const selectedSkills = data?.filter((item) => item?.selected);
    dispatch(
      skillsAction.getSkills({
        chosenRole: employeeRole,
        skills: data,
        selectedSkills,
      })
    );
  };
export const addSkillsToEmployee = (skills, selectedSkills) => (dispatch) => {
  console.log(skills, selectedSkills);
  dispatch(
    skillsAction.addSkillsToEmployee({
      skills,
      selectedSkills,
    })
  );
};

export const addResponsibilities = (data, employeeId) => (dispatch) => {
  const responsibilities = [];
  Object?.keys(data || {})?.forEach((key) => {
    if (data[key]?.selected) {
      responsibilities.push(key);
    }
  });
  dispatch(
    skillsAction?.updateArrayOfResponsibilities({
      responsibilities,
      employeeId,
    })
  );
};

export const addAllResponsibilities = (data, employeeId) => (dispatch) => {
  const responsibilities = [];
  Object?.keys(data || {})?.forEach((key) => {
    responsibilities.push(key);
  });
  dispatch(
    skillsAction?.updateArrayOfResponsibilities({
      responsibilities,
      employeeId,
    })
  );
};
export const updateResponsibilities = (responsibilitie) => (dispatch) => {
  dispatch(skillsAction.updateResponsibilities(responsibilitie));
};
export const sendResponsibilites = (data) => async (dispatch) => {
  try {
    await sendResponsibilitesServices(data);
  } catch (error) {}
};

export const resetResponsibilites =
  (employeeRole, employeeId,data) => (dispatch) => {
    dispatch(
      skillsAction?.resetresponsibilties({
        employeeRole,
        employeeId,data
      })
    );
  };
export const saveResume = (resume) => (dispatch) => {
  dispatch(skillsAction?.saveResume(resume));
};
export const buttonHandler = () => (dispatch) => {
  dispatch(skillsAction?.updateButton());
};
export const createExperience = () => (dispatch) => {
  dispatch(skillsAction?.createExperience());
};
// setEmployeeId

export const setEmployeeIdHandler = (employeeId) => (dispatch) => {
  dispatch(skillsAction?.setEmployeeId(employeeId));
};
export const editExperience = (employeeId,data)=>dispatch=>{
  dispatch(skillsAction?.editExperience({employeeId,data}))
}
export const fillWorkExperienceData =
  ({ key, value }) =>
  (dispatch) => {
    dispatch(skillsAction?.fillWorkExperienceData({ key, value }));
  };

export const editEmpoyeeName = (name) => (dispatch) => {
  dispatch(skillsAction?.editEmpoyeeName(name));
};
// editEmpoyeeSpeciality

export const editEmpoyeeSpeciality = (speciality) => (dispatch) => {
  dispatch(skillsAction?.editEmpoyeeSpeciality(speciality));
};
// editTotalExperience

export const editTotalExperience = (totalExperience) => (dispatch) => {
  dispatch(skillsAction?.editTotalExperience(totalExperience));
};

export const editSummary = (summary) => (dispatch) => {
  dispatch(skillsAction?.editSummary(summary));
};

export const addEducation = (education) => (dispatch) => {
  dispatch(skillsAction?.addEducation(education));
};
export const addLanguage = (language) => (dispatch) => {
  dispatch(skillsAction?.addLanguage(language));
};
export const addExperienceId = (id) => (dispatch) => {
  dispatch(skillsAction?.addExperienceId(id));
};
export const addExperienceTechnology = (technology, experienceId) => (dispatch) => {
  dispatch(skillsAction?.addExperienceTechnology({ technology, experienceId }));
};

export const AddProjectAction = (project) => (dispatch) => {
  dispatch(skillsAction?.AddProject(project));
};
export const editEducation = (education) => (dispatch) => {
  dispatch(skillsAction?.editEducation(education));
};
export const editLanguage = (language) => (dispatch) => {
  dispatch(skillsAction?.editLanguage(language));
};

export const editResponsibilities = ( employeeId,data )=>dispatch=>{
  dispatch(skillsAction?.editResponsibilities( {employeeId,data} ))
}
export const deleteExperienceAction = (id)=>dispatch=>{
  dispatch(skillsAction?.deleteExperience(id))
}
export const editTechnologiesSkills= (technologies)=>dispatch=>{
  dispatch(skillsAction?.editTechnologiesSkills(technologies));

}
export const editExperienceTechnologies= (technologies,id)=>dispatch=>{
  dispatch(skillsAction?.editExperienceTechnologies({technologies,id}));

}
export const editProjectAction = (data,id)=>dispatch=>{
  dispatch(skillsAction?.editProject({data,id}));
}
export const deleteProjectAction = (id)=>dispatch=>{
  dispatch(skillsAction?.deleteProject(id));
}
export const addProjectTechnology = (technology, projectId) => (dispatch) => {
  dispatch(skillsAction?.addProjectTechnology({ technology, projectId }));
}
export const editProjectTechnologies= (technologies,id)=>dispatch=>{
  dispatch(skillsAction?.editProjectTechnologies({technologies,id}));

}
import React, { useEffect, useState } from "react";
import classes from "./JobResponsibilities.module.css";
import ResponsibilitieCard from "./ResponsibilitieCard";
import { useSelector, useDispatch } from "react-redux";

import {
  addResponsibilities,
  addAllResponsibilities,
} from "../../Store/Actions";

const AddButtonComponenet = ({
  title = "Add all",
  addHandler,
  disabled = false,
}) => {
  return (
    <button
      className={
        disabled
          ? classes.addButtonComponenetDisabled
          : classes.addButtonComponenet
      }
      onClick={addHandler}
      disabled={disabled}
    >
      <span>{title}</span>
    </button>
  );
};
export default function JobResponsibilities({ setOpen }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { employeeSkills, employeeId, arrayOfResponsibilities } = useSelector(
    ({ skillsReducer }) => {
      return {
        skills: skillsReducer?.skills,
        employeeSkills: skillsReducer?.employeeSkills,
        selectedResponsibilities: skillsReducer?.selectedResponsibilities,
        arrayOfResponsibilities: skillsReducer?.arrayOfResponsibilities,
        employeeId: skillsReducer?.employeeId,
        allResponsibilities: skillsReducer?.allResponsibilities,
      };
    }
  );
  const formatedData = () => {
    return employeeSkills?.reduce((acc, item) => {
      if (item?.responsibility in acc) {
        return {
          ...acc,
          [item?.responsibility]: {
            ...acc[item?.responsibility],
            category: [...acc[item?.responsibility]?.category, item?.label],
            selected: false,
          },
        };
      } else {
        return {
          ...acc,
          [item?.responsibility]: {
            category: [item?.label],
            selected: false,
          },
        };
      }
    }, {});
  };

  useEffect(() => {
    employeeSkills && setData(formatedData());
  }, [employeeSkills]);

  const selectResponsibilites = (e, title) => {
    let obj = {
      ...data,
      [title]: {
        ...data?.[title],
        selected: !data?.[title]?.selected,
      },
    };

    dispatch(addResponsibilities(obj, employeeId));

    setData(obj);
  };

  const addAll = () => {
    dispatch(addAllResponsibilities(data,employeeId));
    setOpen(false);
  };
  
  const selectedResponsibilitiesHandler = () => {
    
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <section className={classes.responsibilitiesCardWrapper}>
        {Object.keys(data || {})?.map((item, index) => {
          return (
            <ResponsibilitieCard
              key={item}
              id={item}
              skill={data?.[item]?.category}
              description={item}
              index={index + 1}
              selectResponsibilites={selectResponsibilites}
              checked={data?.[item]?.selected}
            />
          );
        })}
      </section>
      <div className={classes.buttonWrapper}>
        <AddButtonComponenet
          title="Add selected"
          disabled={!arrayOfResponsibilities?.[employeeId]?.length}
          addHandler={selectedResponsibilitiesHandler}
        />
        <AddButtonComponenet
          title="Add all"
          disabled={false}
          addHandler={addAll}
        />
      </div>
    </div>
  );
}

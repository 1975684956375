import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editResponsibilities } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function EditResponsibilities({ setOpen = () => {} }) {
  const dispatch = useDispatch();
  const { resume, employeeId } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
      employeeId: skillsReducer?.employeeId,
    };
  });
  const [responsibility, setResponsibilities] = useState((prev) => {
    const responsibility = resume?.work?.[employeeId]?.responsibility
      ? resume?.work?.[employeeId]?.responsibility?.join("\n\n")
      : "";
    return responsibility;
  });
  const onChangeHandler = (e) => {
    setResponsibilities(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault();
    const newResponsibility = responsibility?.split("\n");
    const newArr = newResponsibility?.reduce((acc, item) => {
      if (item !== "") {
        return [...acc, item];
      } else {
        return [...acc];
      }
    }, []);
    dispatch(editResponsibilities(employeeId, newArr));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
    width: "800px",
    minHeight: "400px",
    maxHeight: "600px",
  };
  const textareaStyle = {
   width:"100%",
    minHeight: "400px",
    padding: "19px 16px",
    boxSizing: "border-box",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    resize: "none",
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",

    color: "#1D2C34",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <textarea
        placeholder="Edit Responsibilities"
        value={responsibility}
        onChange={onChangeHandler}
        style={{ ...textareaStyle }}
      />
      <Buttons
        saveHandler={() => {}}
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

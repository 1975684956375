import React from "react";
import Box from "@mui/material/Box";
import Modalui from "@mui/material/Modal";
import classes from "./modal.module.css";
import close from "../../assets/close.svg";

const style = {
  width: "500px",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  position: "absolute",
  top: "120px",
  left: "50%",
  gap: "32px",
  transform: "translate(-50%, 0%)",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  padding: "24px",
  boxSizing: "border-box",
};

const Modal = (props) => {
  return (
    <div>
      <Modalui
        keepMounted
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div className={classes.heading}>
            <h3>{props?.header ? props?.header : "Add new user"}</h3>
            <button onClick={props.handleClose}>
              <img className={classes.close} src={close} alt="" />
            </button>
          </div>
          <form onSubmit={props.submitHandler}>
            <div className={classes.inputs}>
              {props.children}
              {/* <p className={classes.note}>
                (users that you invite will get same password as yours)
              </p> */}
              <button
                className={
                  props.condition ? classes.activeBtn : classes.disableBtn
                }
              >
                {props?.buttonText ? props?.buttonText : "Generate Invite"}
              </button>
              {props.errorMessage && (
                <p className={classes.errorMessage}>{props.errorMessage}</p>
              )}
            </div>
          </form>
        </Box>
      </Modalui>
    </div>
  );
};
export default Modal;

import React, { useEffect, useState } from "react";
import FoldersCards from "./FoldersCards";
import Header from "../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchFolderActionError,
  getFoldersAction,
} from "../../Store/Actions/historyFoldersActions";
import ModalAddFolder from "./ModalAddFolder";
import ModalEditFolder from "./ModalEditFolder";
import ModalDeleteFolder from "./ModalDeleteFolder";

function Folders({ onOpenshowResume }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.historyFolders);

  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const getFolders = async (searchString = search) => {
    dispatch(getFoldersAction(searchString));
  };

  const [modalState, setModalState] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const openModal = (modal) => {
    setModalState({ ...modalState, [modal]: true });
  };

  const closeModal = () => {
    setModalState({ add: false, edit: false, delete: false });
    dispatchFolderActionError(dispatch, "");
  };

  useEffect(() => {
    getFolders();
  }, []);

  const searchHandler = (e) => {
    const searchValue = e.target?.value;

    setSearch(searchValue);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      getFolders(searchValue);
    }, 1000);
    setTimer(newTimer);
  };
  const count = data.length;

  return (
    <>
      <Header
        text={{ button: "Add new folder", heading: "History" }}
        count={`${count} ${count > 1 ? "Folders" : "Folder"}`}
        handler={() => {
          openModal("add");
        }}
        placeholder="Search folder"
        onChangeHandler={searchHandler}
      />
      <FoldersCards
        folders={data}
        onOpenshowResume={onOpenshowResume}
        onOpenEdit={() => {
          openModal("edit");
        }}
        onOpenDelete={() => {
          openModal("delete");
        }}
      />

      <ModalAddFolder
        search={search}
        closeModal={closeModal}
        isOpen={modalState.add}
      />
      <ModalEditFolder closeModal={closeModal} isOpen={modalState.edit} />
      <ModalDeleteFolder closeModal={closeModal} isOpen={modalState.delete} />
    </>
  );
}

export default Folders;

import React from "react";
import classes from "./Style.module.css";

export default function GeneratButton({ active, genrateHandler,icon,title }) {
  return (
    <button
      className={
        active ? classes.generatButtonActive : classes.generatButtonInactive
      }
      onClick={genrateHandler}
    >
      <img src={icon} alt="" />
      <span>{title}</span>
    </button>
  );
}

import React from "react";
import classes from "./actionsButton.module.css";
function DeleteButton(props) {
  return (

    <button style={props.style} onClick={()=>{props.clickHandler(props?.id,props?.data)}} className={classes.button}>

  
      {props.text}
    </button>
  );
}

export default DeleteButton;

import React from "react";
import folderIcon from "../../assets/folder.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import classes from "./Folders.module.css";
import { useDispatch } from "react-redux";
import { setCurrentFolder } from "../../Store/Actions/historyFoldersActions";

function FoldersCards({ folders, onOpenEdit, onOpenDelete, onOpenshowResume }) {
  const dispatch = useDispatch();

  return (
    <div className={classes.wapper}>
      <div className={classes.folders}>
        {folders.map((folder) => (
          <div key={folder.folderID} className={classes.folder}>
            <button
              onClick={() => {
                onOpenshowResume();
                dispatch(setCurrentFolder(folder));
              }}
              className={classes.folder_items}
            >
              <img
                className={classes.imgIconFolder}
                src={folderIcon}
                alt="icon"
              />
              <p>{folder.folderName}</p>
            </button>
            <div className={classes.actions}>
              <button
                className={classes.action_btn}
                onClick={() => {
                  onOpenEdit();
                  dispatch(setCurrentFolder(folder));
                }}
              >
                <img
                  className={classes.action_icon}
                  src={editIcon}
                  alt="icon"
                />
              </button>
              <button
                className={classes.action_btn}
                onClick={() => {
                  onOpenDelete();
                  dispatch(setCurrentFolder(folder));
                }}
              >
                <img
                  className={classes.action_icon}
                  src={deleteIcon}
                  alt="icon"
                />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FoldersCards;

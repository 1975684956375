import React, { useState } from "react";
import classes from "./resizable.module.css";

function Resizable({ children, scrollable = false }) {
  const [height, setHeight] = useState(null); // Initial width of the div

  const handleMouseDown = (e) => {
    const startY = e.clientY;
    const startHeight = height;

    const handleMouseMove = (e) => {
      const newWidth = startHeight + e.clientY - startY;
      setHeight(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className={classes.resize}
      style={{
        height: height ? `${height}px` : "auto",
        overflowY: scrollable ? "auto" : "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      onMouseDown={handleMouseDown}
    >
      {children}
    </div>
  );
}

export default Resizable;

import React from "react";
import zoho from "../../assets/Zoho.svg";
import classes from "./Style.module.css";
export default function InputTypeFile({getFile=()=>{},disabled,option=true}) {
  return (
    <div className={classes.fileWrapper}>
     { option&&<div className={classes.header}>
        <span className={classes.heading}>Import CV </span>{" "}
        <span className={classes.subHeading}>(Optional)</span>
      </div>}
      <label className={classes.label} style={{cursor:!disabled?"pointer":"no-drop"}}>
        <img src={zoho} alt="import from Zoho" />
        <span> Import from Zoho People</span>
        <input type="file" onChange={getFile} disabled={disabled}/>
      </label>
    </div>
  );
}

import React, { Fragment } from "react";
import Row from "./Row";
import clasessTable from "./table.module.css";
import { v4 as uuidv4 } from "uuid";
import upDown from "../../assets/upDown.svg";
import line from "../../assets/line.png";
const Table = (props) => {
  return (
    <table className={clasessTable.table}>
      {props.data.map((element, i) => {
        return (
          <tbody key={uuidv4()} >
            {i === 0 && (
              <tr >
                {element?.map((element) => {
                  return (
                    <th key={uuidv4()} style={{ flex: `${element.width}` }}>
                      {element.lable !== "" && (
                        <img className={clasessTable.upDown} src={upDown} />
                      )}
                      <p>{element.lable}</p>
                    </th>
                  );
                })}
              </tr>
            )}

            {i !== 0 && (
              <Fragment key={uuidv4()} >
                <tr className={clasessTable.line}>
                  <td className={clasessTable.line}>
                    <img className={clasessTable.line} src={line} />
                  </td>
                </tr>
                <tr key={uuidv4()}>
                  {props?.data[0]?.map((header, i) => {
                    return (
                      <Row 
                      key={uuidv4()}
                        header={header}
                        data={element}
                        clickHandler={props.clickHandler}
                        to={`${props.to}${element?.examId}`}
                      />
                    );
                  })}
                </tr>
              </Fragment>
            )}
          </tbody>
        );
      })}
    </table>
  );
};

export default Table;

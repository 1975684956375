// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans&family=Montserrat:wght@100;200;300;400;500;600;700&family=Roboto:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_container__fCNuH {\n  width: 100%;\n  display: grid;\n  background: #F9FAFE;\n  grid-template-rows: 200px minmax(calc(100vh - 400px), 1fr) 200px;\n  box-sizing: border-box;\n  align-items: center;\n}\n.Home_homeBody__k3p9K{\n  align-self: baseline;\n  display: flex;\n  gap: 40px;\n  justify-content: center;\n  align-items: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/Screens/Home.module.css"],"names":[],"mappings":"AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gEAAgE;EAChE,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,oBAAoB;EACpB,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans&family=Montserrat:wght@100;200;300;400;500;600;700&family=Roboto:wght@500&display=swap');\n.container {\n  width: 100%;\n  display: grid;\n  background: #F9FAFE;\n  grid-template-rows: 200px minmax(calc(100vh - 400px), 1fr) 200px;\n  box-sizing: border-box;\n  align-items: center;\n}\n.homeBody{\n  align-self: baseline;\n  display: flex;\n  gap: 40px;\n  justify-content: center;\n  align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Home_container__fCNuH",
	"homeBody": "Home_homeBody__k3p9K"
};
export default ___CSS_LOADER_EXPORT___;

import { useDispatch ,useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-aspire.svg";
import InputControl from "../../Components/Common/InputControl";
import LoginRequest from "../../Requests/LoginRequest";
import { loginActions } from "../../Store/Slices/LoginSlice";
import classes from "./Login.module.css";
import { useEffect } from "react";
const Login = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const userData=useSelector(state=>state.login)
  const emailHanlder = (e) => {
    dispatch(loginActions.userEmail(e.target.value))
  };
  const passwordHanlder = (e) => {
  dispatch(loginActions.userPassword(e.target.value))
  };
  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(LoginRequest({userData,navigate}))
  };

  const token = localStorage.getItem("Authorization");
  useEffect(() => {
    if (token) return navigate("/generate-resume");
  },[]);
  return (
    <div className={classes["login-container"]}>
      <section className={classes["login-content"]}>
        <img src={logo} className={classes["logo"]} alt="Not Available" />
        <form onSubmit={loginHandler}>
          <InputControl
            label="Email"
            placeholder="Email"
            type="email"
            onChange={emailHanlder}
            value={userData.email}

          />
          <InputControl
            label="Password"
            placeholder="Password"
            type="password"
            onChange={passwordHanlder}
            value={userData.password}
          />
      <p className={classes["error"]}>{userData.status}</p>
          <button className={classes["login-btn"]}>Login</button>
        </form>
        <p className={classes["copy-right"]}>
          2022 © ASPIRE All rights reserved
        </p>
      </section>
    </div>
  );
};

export default Login;

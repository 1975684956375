import {
  deleteRequest,
  getRequest,
  postRequest,
  updateRequest,
} from "../Services";
import { historyFoldersActions } from "../Slices/historyFoldersSlice";

export const getFoldersAction = (search) => async (dispatch) => {
  dispatch(historyFoldersActions.startLoading());
  try {
    const response = await getRequest(`get-folders?search=${search}`);
    if (response.status === 200) {
      let { result, count } = response.data;
      dispatch(
        historyFoldersActions.save({
          result,
          count,
        })
      );
    } else {
      dispatchFolderActionError(dispatch, response.response.data.message);
    }
  } catch (error) {
    dispatchFolderActionError(dispatch, error.message);
    console.log(error);
  }
};

export const addFolderAction = (obj, search, callback) => async (dispatch) => {
  dispatch(historyFoldersActions.startLoading());
  try {
    const response = await postRequest("add-new-folder", obj);
    if (response.status === 200) {
      if (obj.folderName.startsWith(search)) {
        dispatch(historyFoldersActions.add(obj));
      }
      callback();
    } else {
      dispatchFolderActionError(dispatch, response.response.data.message);
    }
  } catch (error) {
    dispatchFolderActionError(dispatch, error.message);
    console.log(error);
  }
};
export const editFolderAction = (obj, callback) => async (dispatch) => {
  dispatch(historyFoldersActions.startLoading());
  try {
    const response = await updateRequest("update-folder", obj);
    if (response.status === 201) {
      dispatch(historyFoldersActions.edit(obj));
      callback();
    } else {
      dispatchFolderActionError(dispatch, response.response.data.message);
    }
  } catch (error) {
    dispatchFolderActionError(dispatch, error.message);
    console.log(error);
  }
};
export const deleteFolderAction = (obj, callback) => async (dispatch) => {
  dispatch(historyFoldersActions.startLoading());
  try {
    const response = await deleteRequest(`delete-folder/${obj.folderID}`);
    if (response.status === 200) {
      dispatch(historyFoldersActions.delete(obj));
      callback();
    } else {
      dispatchFolderActionError(dispatch, response.response.data.message);
    }
  } catch (error) {
    dispatchFolderActionError(dispatch, error.message);
    console.log(error);
  }
};
export const setCurrentFolder = (obj) => async (dispatch) => {
  dispatch(historyFoldersActions.setCurrentFolder(obj));
};

export const dispatchFolderActionError = (dispatch, message) => {
  dispatch(historyFoldersActions.setError(message));
};

import React from "react";
import aspireLogo from "../../assets/AspireLogo.svg";
import classes from './Header.module.css'
export default function Header() {
  return (
    <div className={classes.header}>
      <img src={aspireLogo} alt="aspireLogo" />
      <span>Job responsibilites Generator</span>
    </div>
  );
}

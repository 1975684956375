import React from 'react'
import classes from './Body.module.css'
import Skill from "../Skills/Skill";
import {  useSelector } from "react-redux";
export default function RequiredSkills() {
  const { employeeSkills } = useSelector(({ skillsReducer }) => {
    return {
      employeeSkills: skillsReducer?.employeeSkills,
    };
  });
  return (
    <div className={classes.skillWrapper}>
      <span className={classes.skillHeading}>Required skills</span>
      {employeeSkills?.map((skill) => {
        return (
          <Skill
            skill={skill?.title}
            active={!skill.active}
            key={skill?.title}
            id={skill?.title}
            cursor="auto"
          />
        );
      })}
    </div>
  )
}

import { useDispatch, useSelector } from "react-redux";
import ResumesHeader from "./ResumesHeader";
import Table from "./Table";
import Paginations from "../Common/Pagination";
import { useEffect, useState } from "react";
import { getHistoriesAction } from "../../Store/Actions/histories";
import ModalDeleteResume from "./ModalDeleteResume";
import { saveResume } from "../../Store/Actions";
import { useNavigate } from "react-router-dom";

const ResumesInHistory = ({ onBack }) => {
  const { currentFolder } = useSelector((state) => state.historyFolders);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);

  const firstRow = [
    { lable: "name", width: "2" },
    { lable: "role", width: "2" },
    { lable: "Actions", width: ".5" },
  ];
  const [data, setData] = useState([firstRow]);
  const [count, setCount] = useState(0);
  const setDataHandler = (value) => {
    setData(() => {
      return [firstRow, ...value.result];
    });
    setCount(value.count);
  };
  const confirmDelete = (payload) => {
    const newData = data.filter((resum) => resum?.id !== payload);
    setData(newData);
  };

  const getHistories = async (page = 1, limit = 5, searchString = search) => {
    getHistoriesAction(
      currentFolder.folderID,
      page,
      limit,
      searchString,
      setDataHandler
    );
  };

  useEffect(() => {
    getHistories(1, 5);
  }, []);

  const viewHandler = (value) => {
    dispatch(saveResume(value.resume));
    navigate("/resume-preview");
  };
  const searchHandler = (e) => {
    const searchValue = e.target?.value;

    setSearch(searchValue);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      getHistories(1, 5, searchValue);
    }, 1000);
    setTimer(newTimer);
  };
  const [isModaleDeleteOpen, setIsModaleDeleteOpen] = useState(false);
  const [currentResumeId, setCurrentResumeId] = useState(null);
  const deleteHandler = (value) => {
    setCurrentResumeId(value.id);
    setIsModaleDeleteOpen(true);
  };
  const onCloseModal = () => {
    setIsModaleDeleteOpen(false);
  };

  return (
    <div>
      <ResumesHeader
        heading={currentFolder.folderName}
        count={`${count} ${count > 1 ? "Resumes" : "Resume"}`}
        placeholder="Search folder"
        onChangeHandler={searchHandler}
        onBack={onBack}
      />
      <Table data={data} clickHandler={{ deleteHandler, viewHandler }} />
      <Paginations
        count={count}
        current={data.length - 1}
        PaginationHandler={getHistories}
      />
      <ModalDeleteResume
        isOpen={isModaleDeleteOpen}
        closeModal={onCloseModal}
        deletedID={currentResumeId}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default ResumesInHistory;

import React from 'react';
import classes from "./header.module.css";
import GreenBtn from '../Common/Button';
import plus from '../../assets/blueplus.svg';
import Searchinput from './SearchInput';
import search from "../../assets/search.svg"

function Header(props) {
    return (
        <div className={classes.hesderWrapper}>
        <div className={classes.upper}>
            <div className={classes.info}>
                <h1 > {props.text.heading} </h1>
                <div>
                    <p >{props?.count} </p>
                  
                </div>
            
            </div>
            <GreenBtn img={plus} handler={props.handler} text={props.text.button} style={{minWidth:"175px"}} />

        </div>
        <Searchinput placeholder={props.placeholder} onChangeHandler={props.onChangeHandler} img={search} ></Searchinput>
        </div>
    );
}

export default Header;
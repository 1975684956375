import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AddExperience from "../AddExperience/AddExperience";
import Typography from "@mui/material/Typography";
import EditEmpoyeeName from "../CommonForm/EditEmpoyeeName";
import EditEmployeeSpeciality from "../CommonForm/EditEmployeeSpeciality";
import EditTotalExperience from "../CommonForm/EditTotalExperience";
import EditSummary from "../CommonForm/EditSummary";
import AddEducation from "../CommonForm/AddEducation";
import AddLanguage from "../CommonForm/AddLanguage";
import AddTechnology from "../CommonForm/AddTechnology";
import AddProject from "../CommonForm/AddProject";
import EditEducation from "../CommonForm/EditEducation";
import EditLanguage from "../CommonForm/EditLanguage";
import EditExperience from "../CommonForm/EditExperience";
import EditResponsibilities from "../CommonForm/EditResponsibilities";
import DeleteExperience from "../CommonForm/DeleteExperience";
import EditTechnologiesSkills from "../CommonForm/EditTechnologiesSkills";
import EditTechnologies from "../CommonForm/EditTechnologies";
import EditProject from "../CommonForm/EditProject";
import DeleteProject from "../CommonForm/DeleteProject";
import { addExperienceTechnology,addProjectTechnology,editExperienceTechnologies,editProjectTechnologies} from "../../Store/Actions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: 100,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "24px",
  gap: "24px",
  border: "none",
  background: "#FFFFFF",
  borderRadius: "10px",
};

export default function AddResponsibilitiesModal({
  setOpen,
  open,
  typeOfModal = "",
}) {
  const controller = (key) => {
    return {
      editEmpoyeeName: <EditEmpoyeeName setOpen={setOpen} />,
      editEmployeeٍٍٍSpecialty: <EditEmployeeSpeciality setOpen={setOpen} />,
      editTotalExperience: <EditTotalExperience setOpen={setOpen} />,
      editSummary: <EditSummary setOpen={setOpen} />,
      AddEducation: <AddEducation setOpen={setOpen} />,
      editEducation: <EditEducation setOpen={setOpen} />,
      AddLanguage: <AddLanguage setOpen={setOpen} />,
      editLanguage: <EditLanguage setOpen={setOpen} />,
      editTechnologiesSkills: <EditTechnologiesSkills setOpen={setOpen} />,
      addExperience: <AddExperience setOpen={setOpen} />,
      editExperience: <EditExperience setOpen={setOpen} />,
      deleteExperience: <DeleteExperience setOpen={setOpen} />,
      editResponsibilities: <EditResponsibilities setOpen={setOpen} />,
      addProject: <AddProject setOpen={setOpen} />,
      editProject: <EditProject setOpen={setOpen} />,
      deleteProject: <DeleteProject setOpen={setOpen} />,
      addExperienceTechnology: <AddTechnology addTechnology={addExperienceTechnology} setOpen={setOpen} />,
      editExperienceTechnologies: <EditTechnologies type="work" editTechnologies={editExperienceTechnologies} setOpen={setOpen} />,
      addProjectTechnology: <AddTechnology addTechnology={addProjectTechnology} setOpen={setOpen} />,
      editProjectTechnologies: <EditTechnologies type="project" editTechnologies={editProjectTechnologies} setOpen={setOpen} />
    }[key];
    };
  const handleClose = () => setOpen(false);

  const getHeading = (key) => {
    return {
      editEmpoyeeName: "Edit Name",
      editEmployeeٍٍٍSpecialty: "Edit Specialty",
      editTotalExperience: "Edit Total Experience",
      editSummary: "Edit Summary",
      AddEducation: "Add Education & Certifcate",
      addTechnology: "Add Technology",
      AddLanguage: "Add Language",
      addExperience: "Add Experience",
      addProject: " Add Project",
      editExperience: "Edit Experience",
      editResponsibilities: "Edit Responsibilities",
      deleteExperience: "Are you want to delete this section",
      deleteProject: "Are you want to delete this section",
    }[key];
  };
  const styles = {
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "26px",
    color: "#1D2C34",
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <header style={styles}>{getHeading(typeOfModal)}</header>
            <Typography
              id="transition-modal-title"
              variant="h10"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "27px",
              }}
            >
              {controller(typeOfModal)}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { Fragment, useState } from "react";
import classes from "./rightsection.module.css";
import { v4 as uuid } from "uuid";
import Technologies from "./Technologies";
import { useSelector } from "react-redux";
import AddResponsibilityBtn from "../Common/AddResponsibilityBtn";
import AddResponsibilitiesModal from "../Common/AddResponsibilitiesModal";
import {
  resetResponsibilites,
  addExperienceId,
  setEmployeeIdHandler,
} from "../../Store/Actions";
import Modal from "../../Components/Common/Modal";
import { useDispatch } from "react-redux";

const RightSection = (props) => {
  const dispatch = useDispatch();
  const { viewAddResponsibilitiesModal } = useSelector(({ skillsReducer }) => {
    return {
      viewAddResponsibilitiesModal: skillsReducer?.viewAddResponsibilitiesModal,
    };
  });
  const [openGeneralModal, setOpenGeneralModal] = useState(false);
  const [open, setOpen] = useState(viewAddResponsibilitiesModal);
  const [title, setTitle] = useState("");
  const addResponsibility = (employeeId, employeeRole) => {
    dispatch(resetResponsibilites(employeeRole, employeeId));
  };
  const addController = (title) => {
    setTitle(title);
    setOpenGeneralModal(true);
  };

  return (
    <div className={classes.rightside}>
      {open && (
        <AddResponsibilitiesModal
          open={open}
          setOpen={setOpen}
          heading="Add Responsibilities"
        />
      )}
      {openGeneralModal && (
        <Modal
          open={openGeneralModal}
          setOpen={setOpenGeneralModal}
          typeOfModal={title}
        />
      )}
      <div className={classes.infocontainer}>
        <div
          className={
            props?.data?.work?.length ? classes.section : classes.hideSection
          }
        >
          <h3 className={classes.title}>EXPERIENCE </h3>
          {props?.data?.work?.map((item, i) => {
            return (
              <Fragment key={uuid()}>
                <div className={classes.info}>
                  <div className={classes.informations}>
                    <h4 className={classes.date}>
                      {!item?.company ? null : (
                        <p className={classes.company}>{item?.company}</p>
                      )}
                      {item?.date}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "24px",
                        marginTop: "12px",
                      }}
                    >
                      <h2 className={classes.spicilization}>{item?.title}</h2>
                      <button
                        className={classes["btn-edit"]}
                        onClick={(e) => {
                          dispatch(setEmployeeIdHandler(i));
                          setTitle("editExperience");
                          setOpenGeneralModal((prev) => !prev);
                        }}
                      >
                        Edit
                      </button>

                      <button
                        className={classes["btn-edit"]}
                        style={{ color: "red" }}
                        onClick={(e) => {
                          dispatch(setEmployeeIdHandler(item?.id));
                          setTitle("deleteExperience");
                          setOpenGeneralModal((prev) => !prev);
                        }}
                      >
                        Remove
                      </button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "24px",
                      }}
                    >
                      <div>
                        <p
                          className={
                            item?.responsibility?.length
                              ? classes.techheader
                              : classes.hideTechheader
                          }
                        >
                          Responsibilities
                        </p>
                      </div>
                      <button
                        className={classes["btn-edit"]}
                        onClick={(e) => {
                          dispatch(setEmployeeIdHandler(i));
                          setTitle("editResponsibilities");
                          setOpenGeneralModal((prev) => !prev);
                        }}
                      >
                        Edit
                      </button>
                    </div>

                    <ul>
                      {item?.responsibility?.map((element) => {
                        return (
                          <li key={uuid()} className={classes.data}>
                            {element}
                          </li>
                        );
                      })}
                    </ul>
                    <AddResponsibilityBtn
                      btnText="Add Responsibility"
                      style={{ marginTop: "20px", paddingLeft: "25px" }}
                      paragraphStyle={{ fontSize: "12px" }}
                      iconStyle={{ width: "12px", height: "12px" }}
                      function={() => {
                        setOpen(true);
                        dispatch(setEmployeeIdHandler(item?.id));
                        addResponsibility(item?.id, item?.title);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className={classes["btn-edit"]}
                      onClick={(e) => {
                        dispatch(setEmployeeIdHandler(i));
                        setTitle("editExperienceTechnologies");
                        setOpenGeneralModal((prev) => !prev);
                      }}
                    >
                      Edit
                    </button>
                    <Technologies item={item?.TECHNOLOGIES} />

                    <AddResponsibilityBtn
                      btnText="Add Technology"
                      style={{ marginTop: "20px", paddingLeft: "25px" }}
                      paragraphStyle={{ fontSize: "12px" }}
                      iconStyle={{ width: "12px", height: "12px" }}
                      function={(e) => {
                        dispatch(addExperienceId(i));
                        setTitle("addExperienceTechnology");
                        setOpenGeneralModal((prev) => !prev);
                      }}
                    />
                  </div>
                </div>
                {i !== props?.data?.work?.length - 1 && (
                  <div className={classes.line}></div>
                )}
              </Fragment>
            );
          })}
          <div className={classes["new-experience"]}>
            <AddResponsibilityBtn
              btnText="Add Experience"
              function={() => {
                addController("addExperience");
              }}
            />
          </div>
        </div>
        <div
          className={
            props?.data?.project?.length ? classes.section : classes.hideSection
          }
        >
          <h3 className={classes.title}>PROJECTS </h3>
          {props.data?.project?.map((item, i) => {
            return (
              <Fragment key={uuid()}>
                <div className={classes.info}>
                  <div className={classes.informations}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "24px",
                      }}
                    >
                      <h2 className={classes.spicilization}>
                        {item?.NAME?.[0]}
                      </h2>
                      <button
                        className={classes["btn-edit"]}
                        onClick={(e) => {
                          dispatch(setEmployeeIdHandler(i));
                          setTitle("editProject");
                          setOpenGeneralModal((prev) => !prev);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={classes["btn-edit"]}
                        style={{ color: "red" }}
                        onClick={(e) => {
                          dispatch(setEmployeeIdHandler(item.id));
                          setTitle("deleteProject");
                          setOpenGeneralModal((prev) => !prev);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                    {item?.DESCRIPTION?.map((item) => {
                      return (
                        <p key={uuid()} className={classes.data}>
                          {item}
                        </p>
                      );
                    })}
                  </div>
                  <div>
                    <button
                      className={classes["btn-edit"]}
                      onClick={(e) => {
                        dispatch(setEmployeeIdHandler(i));
                        setTitle("editProjectTechnologies");
                        setOpenGeneralModal((prev) => !prev);
                      }}
                    >
                      Edit
                    </button>
                    <Technologies item={item.TECHNOLOGIES} />

                    <AddResponsibilityBtn
                      btnText="Add Technology"
                      style={{ marginTop: "20px", paddingLeft: "25px" }}
                      paragraphStyle={{ fontSize: "12px" }}
                      iconStyle={{ width: "12px", height: "12px" }}
                      function={(e) => {
                        dispatch(addExperienceId(i));
                        setTitle("addProjectTechnology");
                        setOpenGeneralModal((prev) => !prev);
                      }}
                    />
                  </div>
                </div>
                {i !== props?.data?.project?.length - 1 && (
                  <div className={classes.line}></div>
                )}
              </Fragment>
            );
          })}
          <div className={classes["new-project"]}>
            <AddResponsibilityBtn
              btnText="Add Project"
              style={{ marginTop: "20px", paddingLeft: "5px" }}
              function={(e) => {
                setTitle("addProject");
                setOpenGeneralModal((prev) => !prev);
              }}
            />
          </div>
        </div>

        {/* <div className={
            props?.data?.technologies?.length ? classes.section : classes.hideSection
          }>
          <h3 className={classes.title}>KEY SKILLS / TECHNOLOGIES </h3>
          <div className={classes.techImage}>
            {props.data?.technologies?.map((item, i) => {
              return <img key={uuid()} src={item.photo} alt="" />;
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RightSection;

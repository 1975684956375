import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editTechnologiesSkills } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function EditTechnologiesSkills({ setOpen = () => {} }) {
  const dispatch = useDispatch();
  const { resume } = useSelector(({ skillsReducer }) => {
    return {
      resume: skillsReducer?.resume,
    };
  });
  const [technologies, setTechnologies] = useState((prev) => {
    const technologies = resume?.general?.["TECHNOLOGIES & SKILLS"]
      ? resume?.general?.["TECHNOLOGIES & SKILLS"]?.join("\n")
      : "";
    return technologies;
  });
  const onChangeHandler = (e) => {
    setTechnologies(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault();
    const newTechnologies = technologies?.split("\n");
    const newArr = newTechnologies?.reduce((acc,item)=>{
      if(item !==''){
        return [...acc,item]
      }else{
        return [...acc]
      }
    },[])
    dispatch(editTechnologiesSkills( newArr ));
    setOpen(false);
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  const textareaStyle = {
    width: "503px",
    height: "118px",
    padding: "19px 16px",
    boxSizing: "border-box",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    resize: "none",
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",

    color: "#1D2C34",
  };
  return (
    <form style={{ ...style }} onSubmit={saveHandler}>
      <textarea
        placeholder="Edit Technology & Skills"
        value={technologies}
        onChange={onChangeHandler}
        style={{ ...textareaStyle }}
      />
      <Buttons
        saveHandler={() => {}}
        cancelationHandler={() => {
          setOpen(false);
        }}
      />
    </form>
  );
}

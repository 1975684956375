import React from "react";
import classes from "./header.module.css";
import Searchinput from "../Common/SearchInput";
import search from "../../assets/search.svg";
import backIcon from "../../assets/blue-back.svg";

function ResumesHeader(props) {
  return (
    <div className={classes.hesderWrapper}>
      <button onClick={props.onBack} className={classes.backButton}>
        <img src={backIcon} alt="" />
        <p>Back to Folders</p>
      </button>

      <div className={classes.upper}>
        <div className={classes.info}>
          <h1> {props.heading} </h1>
          <div>
            <p>{props?.count} </p>
          </div>
        </div>
      </div>
      <Searchinput
        placeholder={props.placeholder}
        onChangeHandler={props.onChangeHandler}
        img={search}
      ></Searchinput>
    </div>
  );
}

export default ResumesHeader;

import { usersActions } from "../Slices/users";
import {
  getRequest,
  deleteRequest,
  postRequest,
  updateRequest,
} from "../Services";
export const getUsersAction = (page, limit, search) => async (dispatch) => {
  try {
    const { data } = await getRequest(
      `get-users?limit=${limit}&offset=${(page - 1) * limit} &search=${search}`
    );
    let { result, count } = data;
    dispatch(usersActions.save({ result, count }));
  } catch (error) {
    console.log(error);
  }
};
export const deleteUserAction = (id, navigate) => async (dispatch) => {
  try {
    await deleteRequest(`delete-user/${id}`);
    dispatch(usersActions.delete(id));
  } catch (error) {
    console.log(error);
  }
};

export const inviteUserAction = (obj, search, callback) => async (dispatch) => {
  try {
    const response = await postRequest("invite-user", obj);
    if (response.status === 200) {
      if (obj.name.startsWith(search)) {
        dispatch(
          usersActions.add({
            id: response.data,
            email: obj.email,
            role: obj.role,
            name: obj.name,
          })
        );
      }
      callback();
    } else {
      dispatch(usersActions.setError(response.response.data.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const editUserAction = (obj, callback) => async (dispatch) => {
  try {
    const response = await updateRequest(`edit-user`, obj);
    if (response.status === 201) {
      dispatch(usersActions.edit(obj));
      callback();
    } else {
      dispatch(usersActions.setError(response.response.data.message));
    }

    dispatch(usersActions.edit(obj));
  } catch (error) {}
};

import React, { useState } from "react";
import Input from "../Common/Input";
import { useDispatch } from "react-redux";
import { addLanguage } from "../../Store/Actions";
import Buttons from "./Buttons";
export default function AddLanguage({ setOpen }) {
  const dispatch = useDispatch();

  const [language, setLanguage] = useState("");
  const onChangeHandler = (e) => {
    setLanguage(e.target?.value);
  };
  const saveHandler = (e) => {
    e.preventDefault()

    dispatch(addLanguage(language));
    setOpen(false);
    console.log(language,1)
  };
  const style = {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  };
  return (
    
      <form style={{ ...style }} onSubmit={saveHandler}>
        <Input
          text=""
          value={language}
          width="487px"
          placeholder="Add Language"
          type="text"
          onChangeHandler={onChangeHandler}
          name="addeLanguage"
        />
        <Buttons
          saveHandler={()=>{}}
          cancelationHandler={() => {
            setOpen(false);
          }}
        />
      </form>
    
  );
}

import React from "react";
import InputTypeFile from "../Common/InputTypeFile";
import upload from "../../assets/uploadlogo.svg";
import line from "../../assets/line.png";
import excel from "../../assets/PROFILE-TEMPLATE.xlsx";

import classes from "./uploadFile.module.css";
import download from "../../assets/download.svg";
function UploadFile(props) {
  return (
    <div className={classes.container}>
    <div className={classes.uploadEcxel}>
    <button onClick={props.onClickHandler} className={classes.btn}>
        
       
        New Resume
      
    </button>
    <img alt="line" className={classes.line} src={line} />
      <div className={classes.form}>
        <div className={classes.imgandpar}>
          <img className={classes.upimg} src={upload} alt="file" />
          <div>
            <label htmlFor="first" className={classes.clickhere}>
              Upload MS Excel Template
            </label>
            <p>XLS, XSLX are supported </p>
          </div>
        </div>
        <label htmlFor="first" className={classes.inptcont}>
        Select Sheet
          <input
            className={classes.uploadinpt}
            onChange={props.submitHandler}
            id="first"
            name="first"
            type="file"
            accept=".xlsx, .xls, .csv"
          />
        </label>
      </div>
      <img alt="line" className={classes.line} src={line} />
     
      <div className={classes.download}>
        <img src={download} />
        <a href={excel} target="_blank" download>
          Download Template
        </a>
      </div>
    </div>
    <div className={classes.blocksection}>
        <img className={classes.line} src={line} />
        <p> OR</p>
        <img className={classes.line} src={line} />
      </div>
<InputTypeFile option={false}/>
    </div>
  );
}

export default UploadFile;

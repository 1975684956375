import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Body from "../Body/Body";
import JobResponsibilities from "../JobResponsibilities/JobResponsibilities";
import blueBackArow from "../../assets/blueBackArow.svg";
import EditButton from "./EditButton";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: 100,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "32px",
  gap: "27px",
  width: "684px",
  border: "none",
  background: "#FFFFFF",
  borderRadius: "10px",
};

export default function AddResponsibilitiesModal({
  setOpen,
  open,
  heading = "",
}) {
  const [view, setView] = React.useState(false);

  const handleClose = () => setOpen(false);
  // const closeHandler = (e) => {
  //   setOpen(false);
  // };
  const genrateHandler = () => {
    setView((prev) => !prev);
  };
  const styles = {
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "26px",
    color: "#1D2C34",
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h10"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "27px",
              }}
            >
              {view && (
                <EditButton
                  editHandler={() => {
                    setView(false);
                  }}
                  title={"Back"}
                  icon={blueBackArow}
                />
              )}
              <header style={styles}>{heading}</header>
            </Typography>
            {view ? (
              <JobResponsibilities setOpen={setOpen} />
            ) : (
              <Body
                view={view}
                setView={setView}
                genrateHandler={genrateHandler}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

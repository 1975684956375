import { deleteRequest, getRequest, postRequest } from "../Services";

export const getHistoriesAction = async (
  folderID,
  page,
  limit,
  search,
  setter
) => {
  try {
    const response = await getRequest(
      `get-histories?folderID=${folderID}&limit=${limit}&offset=${
        (page - 1) * limit
      }&search=${search}`
    );

    if (response.status === 200) {
      let { result, count } = response.data;
      setter({
        result,
        count,
      });
    } else {
      console.log(response.message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const addHistoryAction = async (data, callback) => {
  try {
    await postRequest(`add-history`, data);
    callback();
    return;
  } catch (error) {
    console.error();
    return error;
  }
};

export const deleteHistoryAction = async (historyID, callback) => {
  try {
    const response = await deleteRequest(`delete-history/${historyID}`);
    if (response.status === 200) {
      callback();
    } else {
      console.log(response.message);
    }
  } catch (error) {
    console.log(error.message);
    console.log(error);
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputControl_input-control__bYrCl {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n}\n\n.InputControl_input-control__bYrCl label {\n  margin-bottom: 8px;\n  font-family: \"Montserrat\";\n  font-weight: 600;\n  color: #1d2c34;\n  font-size: 16px;\n}\n.InputControl_input-control__bYrCl input {\n  height: 55px;\n  border-radius: 5px;\n  border: 1px solid #e8e8e8;\n  padding: 0 10px;\n  outline: none;\n}\n::placeholder {\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 400;\n  font-size: 14px;\n  color: #acabab;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/InputControl.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,aAAa;AACf;AACA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".input-control {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n}\n\n.input-control label {\n  margin-bottom: 8px;\n  font-family: \"Montserrat\";\n  font-weight: 600;\n  color: #1d2c34;\n  font-size: 16px;\n}\n.input-control input {\n  height: 55px;\n  border-radius: 5px;\n  border: 1px solid #e8e8e8;\n  padding: 0 10px;\n  outline: none;\n}\n::placeholder {\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 400;\n  font-size: 14px;\n  color: #acabab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-control": "InputControl_input-control__bYrCl"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import AdminWrapper from "../../Components/Common/AdminWrapper";
import Header from "../../Components/Common/Header";
import Table from "../../Components/Common/Table";
import Pagination from "../../Components/Common/Pagination";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../Components/Skills/Modal";
import {
  getTechnologiesAction,
  editTechnologyAction,
  deleteTechnologyAction,
} from "../../Store/Actions/technologies";
import { addTechnologyAction } from "../../Store/Actions/technologies";
import ConfirmModal from "../../Components/Common/ConfirmModal";

const Skills = (props) => {
  const [search, setSearch] = useState("");
  const [generate, setGenerate] = useState({
    Skills: "",
    photo: "",
  });
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const handleCloseAdd = () => {
    setAddOpen(false);
    setGenerate({
      Skills: "",
      photo: "",
    });
  };
  const handleCloseEdit = () => {
    setEditOpen(false);
    setGenerate({
      Skills: "",
      photo: "",
    });
  };
  const [timer, setTimer] = useState(null);

  const { technologies } = useSelector((state) => state.technologies);
  const dispatch = useDispatch();
  const getTechnologies = async (
    page = 1,
    limit = 5,
    searchString = search
  ) => {
    dispatch(getTechnologiesAction(page, limit, searchString));
  };
  useEffect(() => {
    getTechnologies(1, 5);
  }, []);

  const editHandler = async (id) => {
    setEditOpen(true);
    const technology = technologies?.result.find((user) => user.id === id);

    setGenerate(technology);
  };
  const handleOpen = () => {
    setAddOpen(true);
  };

  const onChangeHandler = ({ target }) => {
    setGenerate((state) => {
      return { ...state, [target.id]: target.value };
    });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      dispatch(addTechnologyAction(generate, search, handleCloseAdd));
    } catch (error) {
      console.log(error);
    }
  };
  const searchHandler = (e) => {
    const searchValue = e.target?.value;
    setSearch(searchValue);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      getTechnologies(1, 5, searchValue);
    }, 1000);
    setTimer(newTimer);
  };
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(editTechnologyAction(generate, handleCloseEdit));
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedID, setDeletedID] = useState(null);
  const openModalDelete = (id) => {
    setDeleteModal(true);
    setDeletedID(id);
  };
  const closeModalDelete = () => {
    setDeleteModal(false);
  };
  const onConfirmDelete = async () => {
    dispatch(deleteTechnologyAction(deletedID));
    closeModalDelete();
  };

  return (
    <AdminWrapper>
      <Header
        text={{ button: "Add new skill", heading: "Skills" }}
        handler={handleOpen}
        onChangeHandler={searchHandler}
        count={`${technologies.count} ${
          technologies.count > 1 ? "Skills" : "Skill"
        }`}
      />

      <Table
        to="/"
        data={technologies.result}
        flex="1.5"
        clickHandler={{ deleteHandler: openModalDelete, editHandler }}
      />
      <Pagination
        count={technologies.count}
        current={technologies.result.length - 1}
        PaginationHandler={getTechnologies}
      />
      <Modal
        open={addOpen}
        submitHandler={submitHandler}
        generate={generate}
        handleClose={handleCloseAdd}
        onChangeHandler={onChangeHandler}
      />
      <Modal
        open={editOpen}
        submitHandler={editSubmitHandler}
        generate={generate}
        handleClose={handleCloseEdit}
        onChangeHandler={onChangeHandler}
      />
      <ConfirmModal
        isOpen={deleteModal}
        closeModal={closeModalDelete}
        headerText="Delete Skill"
        descriptionText="This action will delete the selected skill, are you sure?"
        onConfirm={onConfirmDelete}
      />
    </AdminWrapper>
  );
};

export default Skills;

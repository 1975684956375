import React from "react";
import classes from "./Buttons.module.css";
export default function Buttons({ saveHandler, cancelationHandler }) {
  return (
    <div className={classes.buttonsWrapper}>
      <button
        type="button"
        onClick={cancelationHandler}
        className={classes.cancelButton}
      >
        Cancel
      </button>
      <button type="submit" className={classes.saveButton}>
        Save
      </button>
    </div>
  );
}
